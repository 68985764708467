<template>
  <div style="
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 50px;
    " :style="themePrimaryColor">
    <div class="top-container">
      <TopToolBox :style="themePrimaryColor"/>
    </div>
    <div class="middle-container f-w" id="middle-container">
      <LayoutChart />
      <div class="right-container" id="right-container" :style="themeDarkMode
        ? { 'border-left': '1px solid #2C2929' }
        : { 'border-left': '1px solid #eee' }
        ">
        <right-tool-box />
      </div>
    </div>


    <bottom-toolbox style="z-index:101"></bottom-toolbox>
  </div>
</template>

<script>
import Loader from 'BaseComponents/Loader'
import { mapActions, mapGetters } from 'vuex'
import { setJwt } from '../services/http'
import {
  isClientAccountActive,
  isLogged
} from '../services/user'
import BottomToolbox from './BottomToolbox.vue'
import Chart from './Chart'
import LayoutChart from './LayoutChart.vue'
import RightToolBox from './RightToolBox.vue'
import TopToolBox from './topToolBox/TopToolBox.vue'
import config from '../config.js'

export default {
  name: 'HomeComponent',
  components: {
    Chart,
    TopToolBox,
    // ToolBoxTest,
    BottomToolbox,
    RightToolBox,
    Loader,
    LayoutChart
  },

  async mounted() {    
    this.$store.commit('SET_MINUS_INDEX', this.$route.query?.i ?? 0)
    this.retrieveSymbolsList()
    const token = localStorage.getItem('state')
    if (token) {
      const response = await this.$store.dispatch(
        'user/retrieveUserSubscriptionStatus'
      )
      if (response.isSuccess) {
        if (response.data?.trialEndsIn <= config.SUB_EXP_NOTIFICATION_DAYS && response.data?.status == "trialing") {
          setTimeout(() => {
            this.$store.commit('SET_SUB_EXP_POPUP', true)
          }, config.SUB_EXP_POPUP_TIME);
        }
        await isLogged()
        setJwt()
        setInterval(() => {

          isClientAccountActive().then((d) => {

            if (d.status === 'inactive') {
              //this.$router.push('/products')
            }
          })
        }, 60000 * 30)
      } else {
        // this.$router.push('/products')
      }
    }
    else {
      // console.log("getUserId", this.getUserId);
      localStorage.removeItem('themeDarkModeSave')
      localStorage.removeItem('themeModeSave')
      setTimeout(() => {
        this.$store.commit('SET_INTERVAL_POPUP', true)
      }, config.INTERVAL_POPUP_TIME);
    }

  },

  methods: {
    ...mapActions(['retrieveSymbolsList', 'showBrushData']),
  },
  computed: {
    ...mapGetters([
      'getSymbolsList',
      'isChartLoading',
      'themeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeDarkMode',
      'themeFontColor',
      'getUserId',
    ]),
    showChart() {
      return this.getSymbolsList.length > 0
    },

    // togglebox(){
    //   return !this.togglerighttoolbox ? 'background-color: red' : ''
    //     //  return `@media screen and (max-width: 1000px) { .right-container {display : none}  }`
    // },

    showLoader() {
      let showLoad = !this.showChart || this.isChartLoading
      if (!showLoad) {
        // this.showBrushData()
      }
      return showLoad
    },
  },
}
</script>
<style></style>

<template>
    <!-- <dialog-box size="small" modalHeader="" :open="open" :action="false"> -->
    <sui-modal v-model="open" :closable="false" :closeIcon="false" :size="'small'" class="disableModal"
        @changed="onModalCancel">
        <sui-modal-content class="disable-content">

            <!-- <div style="float: right; font-size: larger;cursor: pointer;" @click="onCancel">x</div> -->
            <CustomImageFullScreen
                style="z-index: 1; background-color: #b0acaced; position: absolute; right: 1.5rem; top: 1.5rem; border-radius: 24%;"
                @click="onCancel" class="closeIcon" :iconName="'close icon'" />

            <img src="/images/ApplicationPopupImg.png" style="width: 100%; height: auto; border-radius: 3%;" />
            <div style="margin: 1.5rem 2rem;">

                <span class="ui" style="font-size: 28px;font-weight: 600;">
                    Harness the power of Protrader.
                </span><br />
                <span style="font-size: 22px; font-weight: 400; ">
                    Scan thousands of stocks in seconds.
                </span><br />
                <span style="font-size: 16px; font-weight: 400;line-height: 2rem;">Psst...Membership includes
                    access to our exclusive trading room</span>
            </div>
            <div style="text-align: center;">
                <sui-button style="font-size: 18px; margin-bottom: 1rem; border-radius: 0.5rem;" negative @click.native="onSave">{{
                    getUserId ? `Upgrade your plan` : `14-day free
                    trial`}} </sui-button>
            </div>

        </sui-modal-content>
        <!-- <template v-slot:action>
            <sui-modal-actions>
                <div style="text-align: center;">
                    <sui-button style="font-size: 18px; border-radius: 0.5rem;" negative @click.native="onSave">{{
                        getUserId ? `Upgrade your plan` : `14-day free
                        trial`}} </sui-button>
                </div>
            </sui-modal-actions>
        </template> -->
    </sui-modal>
    <!-- </dialog-box> -->
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import DialogBox from '../../baseComponents/DialogBox'
import config from '../../config.js'
import CustomImageFullScreen from '@/baseComponents/CustomImageFullScreen.vue'

export default {
    name: 'FeatureDisablePopUp',
    components: {
        DialogBox,
        CustomImageFullScreen
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            monthly_priceId: config.Monthly_priceId
        }
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    methods: {
        ...mapMutations(['SET_RESTRICT_POPUP', 'SET_AUTH_MODAL']),
        onCancel() {
            this.SET_RESTRICT_POPUP(false)
            // this.SET_AUTH_MODAL(true)
        },
        onSave() {
            if (this.getUserId != null) {
                this.$router.push("/products")
            }
            else {
                this.SET_RESTRICT_POPUP(false)
                this.SET_AUTH_MODAL(true)
            }
            this.SET_RESTRICT_POPUP(false)
        }
    },
    computed: {
        ...mapGetters([
            'getUserId'
        ])
    }
}

</script>
<style>
.disableModal {
    font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    color: #131722;
}

.disable-content {
    border-radius: 3% !important;
    padding: 0.9rem !important;
}

.disableModal>div.modal {
    border-radius: 3%;
}
</style>
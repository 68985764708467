<template>
    <div class="left">
        <!-- top image -->
        <div class="logo">
            <img :src="themeDarkMode ? '/images/Icon-ProTrader-darkmode.png' : '/images/Icon-ProTrader-classicmode.png'"
                alt="ProTrader" srcset="" />
        </div>

        <!-- responsive tab -->
        <div class="responsiveTab top-controls-btn" style="gap: 3px">
            <!------------- Chart Type DropDown (Mobile View)------------------------>
            <sui-dropdown class="button secondary mini width-change width-change-max dropdwn-chart" :icon="null">
                <sui-image class="mob-tab-chart-img"
                    :src="chartImgAddress ? chartImgAddress : '/images/chartType/bar.svg'" size="large" />
                <sui-dropdown-menu class="dropdwn-chart-menu" :style="themePrimaryColor">
                    <sui-dropdown-item v-for="item in chartTypeRanges"
                        @click="changeType(item.chartType, item.imagePath)"
                        :style="selectedChartType === item.chartType ? chartRangeStyle() : themeFontColor">
                        <img :src="themeDarkMode ? `${item.imagePath}` : `${item.image2Path}`" class="svgImage"
                            width="15" height="13" />
                        <span class="dropdwn-text">{{ item.chartType }}</span>
                    </sui-dropdown-item>

                    <sui-dropdown-item style="padding-bottom: 8px !important" @click="changeChartToCompareStocksType"
                        :style="themeFontColor">
                        <img :src="themeDarkMode ? `/images/chartType/compare.svg` : `/images/chartType/comparison2.svg`"
                            :alt="`comparison-chart`" class="svgImage" width="15" height="13" />
                        <span class="dropdwn-text">Comparison Chart</span>

                    </sui-dropdown-item>
                    <sui-dropdown-item style="padding-bottom: 8px !important ; display:flex;align-items:center;"
                        @click="toggleVloume" :style="themeFontColor">
                        <img :src="themeDarkMode ? `/images/chartType/volume-chart.svg` : `/images/chartType/toggleVolume2.svg`"
                            :alt="`comparison-chart`" class="svgImage" width="15" height="13" />
                        <span class="dropdwn-text">Toggle Volume</span>

                    </sui-dropdown-item>
                </sui-dropdown-menu>
            </sui-dropdown>

            <!------------- Chart TimeFrame DropDown (Mobile View)------------------------>
            <sui-dropdown :disabled="isChartLoading" :text="chartTimeframe"
                class="button secondary mini width-change width-change-max dropdwn-chart" :icon="null">
                <sui-dropdown-menu class="width-change-item dropdwn-chart-menu" :style="themePrimaryColor">
                    <sui-dropdown-item v-for="item in chartTimeFrameRanges" :content="`${item.timeFrame}`"
                        :style="chartTimeframe == item.timeFrame ? chartRangeStyle() : themeFontColor"
                        :negative="chartTimeframe == item.timeFrame" @click="changeTimeFrame(item.timeFrame)"
                        :data-tooltip="`${item.adverb} View`" data-position="bottom center" data-inverted=""
                        secondary>{{ item.fullName }}
                    </sui-dropdown-item>
                </sui-dropdown-menu>
            </sui-dropdown>

            <!------------- Chart Range DropDown (Mobile View)------------------------>
            <sui-dropdown :text="chartVisibleRange ? chartVisibleRange : '1Y'"
                class="button secondary mini width-change-max dropdwn-chart" :icon="null">
                <sui-dropdown-menu class="width-change-item dropdwn-chart-menu" :style="themePrimaryColor">
                    <sui-dropdown-item v-for="timeRange in filteredTimeRanges" :key="timeRange.range"
                        @click="chartVisibleRange = timeRange.range" :style="chartVisibleRange == timeRange.range ? chartRangeStyle() : themeFontColor
                            " :disabled="isTrainingMode">
                        {{ timeRange.range }}
                    </sui-dropdown-item>
                </sui-dropdown-menu>
            </sui-dropdown>

            <!------------- Chart Layout DropDown (Mobile View)------------------------>
            <sui-dropdown :disabled="isTrainingMode"
                class="button secondary mini width-change width-change-max dropdwn-chart" :icon="null">
                <sui-image class="mob-tab-chart-img" :src="layoutImg ? layoutImg : '/images/layout/layout_4tab.svg'"
                    size="large" />
                <sui-dropdown-menu :style="themePrimaryColor" class=" dropdwn-layout-menu">
                    <sui-dropdown-item v-for="(layout, index) in layoutImageMapping"
                        @click="handleButtonClick(changeLayout, layout.name)" class="layout-item" :key="index" :style="getLayout === layout.name
                            ? chartRangeStyle()
                            : themeFontColor
                            ">
                        <img :src="themeDarkMode ? `${layout.imagePath}` : `${layout.darkImagePath}`" alt="Layout"
                            class="svgImage" width="15" height="13" />
                    </sui-dropdown-item>
                    <sui-dropdown-item class="layout-item" @click="handleButtonClick(saveLayoutFun)"
                        :style="themeFontColor">
                        Save
                    </sui-dropdown-item>

                    <sui-dropdown-item class="layout-item" v-for="(layout, index) in layoutList" :key="index"
                        :style="themeFontColor">
                        <span class="item-text" @click="handleButtonClick(handleItemClick, layout)">
                            {{ layout.layoutName }}
                        </span>
                        <span class="delete-icon" @click.stop="handleButtonClick(openDeleteLayoutPopup, layout)">
                            ✕
                        </span>
                    </sui-dropdown-item>

                </sui-dropdown-menu>
            </sui-dropdown>
        </div>

        <!----------------------- Chart Type full ----------------------->
        <div class="chart-controls top-controls-btn" id="chart-controls-header">
            <sui-button-group :widths="10" icons>
                <custom-image-icon-button v-for="item in chartTypeRanges" :imageSrc="`${item.imagePath}`"
                    class="svgImage" width="15" height="13"
                    :style="selectedChartType === item.chartType ? themeSecondaryColor : {}"
                    :isToggled="selectedChartType === item.chartType" @click="changeType(item.chartType)"
                    :tooltipText="item.toolTip" tooltipPosition="bottom center" />

                <custom-image-icon-button imageSrc="/images/chartType/compare.svg" :style="selectedChartType === 'CompareChartMain'
                    ? themeSecondaryColor
                    : {}
                    " :isToggled="selectedChartType == 'CompareChartMain'" @click="changeChartToCompareStocksType"
                    tooltipText="Comparison Chart" tooltipPosition="bottom center" :disabled="isTrainingMode" />
                <custom-image-icon-button :isToggled="chartToggleVolume" imageSrc="/images/chartType/volume-chart.svg"
                    @click="toggleVloume" tooltipText="Toggle Volume" tooltipPosition="bottom center"
                    :style="chartToggleVolume ? themeSecondaryColor : {}" />
            </sui-button-group>
        </div>

        <!----------------------- Chart Type Dropdown (small-laptop/tab) ----------------------->
        <sui-dropdown class="button secondary top-controls-direct-btn" id="Chart-Controls-Block1" :icon="null">
            <sui-image style="" class="mob-tab-chart-img"
                :src="chartImgAddress ? chartImgAddress : '/images/chartType/bar.svg'" size="mini" />
            <sui-dropdown-menu :style="themePrimaryColor" class="chart-control-dropdwn">

                <sui-dropdown-item v-for="item in chartTypeRanges" @click="changeType(item.chartType, item.imagePath)"
                    :style="selectedChartType === item.chartType ? chartRangeStyle() : themeFontColor
                        ">
                    <img :src="themeDarkMode ? `${item.imagePath}` : `${item.image2Path}`" class="svgImage" width="15"
                        height="13" :alt="`${item.chartType}`" />
                    <span class="dropdwn-text">{{ item.chartType }}</span>
                </sui-dropdown-item>

                <sui-dropdown-item style="padding-bottom: 8px !important" @click="changeChartToCompareStocksType"
                    :style="themeFontColor">
                    <img :src="themeDarkMode ? `/images/chartType/compare.svg` : `/images/chartType/comparison2.svg`"
                        :alt="`comparison-chart`" width="15" height="13" class="svgImage" />
                    <span class="dropdwn-text">Comparison Chart</span>

                </sui-dropdown-item>
                <sui-dropdown-item style="padding-bottom: 8px !important ; display:flex;align-items:center;"
                    @click="toggleVloume" :style="themeFontColor">
                    <img :src="themeDarkMode ? `/images/chartType/volume-chart.svg` : `/images/chartType/toggleVolume2.svg`"
                        :alt="`comparison-chart`" width="15" height="13" class="svgImage" />
                    <span class="dropdwn-text">Toggle Volume</span>

                </sui-dropdown-item>
            </sui-dropdown-menu>
        </sui-dropdown>

        <!----------------------- Chart Timeframe Dropdown (small-laptop/tab) ----------------------->
        <sui-dropdown :disabled="isChartLoading" :text="chartTimeframe" class="button secondary top-controls-direct-btn"
            id="timeFrameBlock1" :icon="null">
            <sui-dropdown-menu :style="themePrimaryColor" class="chart-timeframe-dropdwn">
                <sui-dropdown-item v-for="item in chartTimeFrameRanges" :content="`${item.timeFrame}`" :style="chartTimeframe == item.timeFrame ? chartRangeStyle() : themeFontColor
                    " :negative="chartTimeframe == item.timeFrame" @click="changeTimeFrame(item.timeFrame)"
                    :data-tooltip="`${item.adverb} View`" data-position="bottom center" data-inverted="" secondary>{{
                        item.fullName }}
                </sui-dropdown-item>
            </sui-dropdown-menu>
        </sui-dropdown>

        <!----------------------- Chart Range Dropdown (small-laptop/tab) ----------------------->
        <sui-dropdown :text="chartVisibleRange ? chartVisibleRange : '1Y'"
            class="button secondary top-controls-direct-btn" id="timeFrameBlock2" :icon="null">
            <sui-dropdown-menu :style="themePrimaryColor" class="chart-range-dropdwn">
                <sui-dropdown-item v-for="timeRange in filteredTimeRanges" :key="timeRange.range"
                    @click="chartVisibleRange = timeRange.range || mob" style="font-size: 0.9em" :style="chartVisibleRange == timeRange.range ? chartRangeStyle() : themeFontColor
                        " :disabled="isTrainingMode">
                    {{ timeRange.range }}
                </sui-dropdown-item>
            </sui-dropdown-menu>
        </sui-dropdown>

        <!----------------------- Chart Layout Dropdown (small-laptop/tab) ----------------------->
        <sui-dropdown :disabled="isTrainingMode" class="button secondary top-controls-direct-btn" :icon="null"
            id="timeFrameBlock3">
            <sui-image class="mob-tab-chart-img" :src="layoutImg ? layoutImg : '/images/layout/layout_4tab.svg'"
                size="large" />
            <sui-dropdown-menu :style="themePrimaryColor" class="dropdwn-layout-menu">
                <sui-dropdown-item class="layout-item" v-for="(layout, index) in layoutImageMapping" :key="index"
                    @click="handleButtonClick(changeLayout, layout.name)">
                    <img :src="themeDarkMode ? `${layout.imagePath}` : `${layout.darkImagePath}`" alt="Layout"
                        class="svgImage" width="15" height="13" />
                </sui-dropdown-item>
                <sui-dropdown-item class="layout-item" @click="handleButtonClick(saveLayoutFun)"
                    :style="themeFontColor">
                    Save
                </sui-dropdown-item>
                <sui-dropdown-item class="layout-item" v-for="(layout, index) in layoutList" :key="index"
                    :style="themeFontColor">
                    <span class="item-text" @click="handleButtonClick(handleItemClick, layout)">
                        {{ layout.layoutName }}
                    </span>
                    <span class="delete-icon" @click.stop="handleButtonClick(openDeleteLayoutPopup, layout)">
                        ✕
                    </span>
                </sui-dropdown-item>

            </sui-dropdown-menu>
        </sui-dropdown>

        <!----------------------- Chart TimeFrame full ----------------------->
        <div class="time-frames-d1-to-M  top-controls-btn" id="time-frames-header">
            <sui-button-group :widths="3" icons>
                <sui-button v-for="item in chartTimeFrameRanges" secondary :content="item.timeFrame"
                    :disabled="isChartLoading" :style="chartTimeframe == item.timeFrame ? themeSecondaryColor : {}"
                    :negative="chartTimeframe == item.timeFrame" @click="changeTimeFrame(item.timeFrame)"
                    :data-tooltip="`${item.adverb} View`" data-position="bottom center" data-inverted="" />
            </sui-button-group>
        </div>

        <!----------------------- Chart Data Range full ----------------------->
        <div class="data-range  top-controls-btn" id="data-range-header">
            <sui-button-group :widths="7" icons>
                <sui-button v-for="timeRange in filteredTimeRanges" :key="timeRange.range" secondary
                    :content="timeRange.range" :style="chartVisibleRange == timeRange.range ? themeSecondaryColor : {}
                        " @click="chartVisibleRange = timeRange.range" :data-tooltip="timeRange.toolTip"
                    data-position="bottom center" data-inverted="" :disabled="isTrainingMode" />

                <!----------------------- Chart Layout Dropdown Full----------------------->
                <sui-dropdown :disabled="isTrainingMode" class="button secondary" :icon="null">
                    <sui-image class="mob-tab-chart-img" :src="layoutImg ? layoutImg : '/images/layout/layout_4tab.svg'"
                        size="large" />
                    <sui-dropdown-menu :style="themePrimaryColor" class="dropdwn-layout-menu">
                        <sui-dropdown-item class="layout-item" v-for="(layout, index) in layoutImageMapping"
                            :key="index" @click="handleButtonClick(changeLayout, layout.name)">
                            <img :src="themeDarkMode ? `${layout.imagePath}` : `${layout.darkImagePath}`" alt="Layout"
                                class="svgImage" width="15" height="13" />
                        </sui-dropdown-item>

                        <sui-dropdown-item class="layout-item" @click="handleButtonClick(saveLayoutFun)"
                            :style="themeFontColor">
                            Save
                        </sui-dropdown-item>

                        <sui-dropdown-item class="layout-item" v-for="(layout, index) in layoutList" :key="index"
                            :style="themeFontColor">
                            <span class="item-text" @click="handleButtonClick(handleItemClick, layout)">
                                {{ layout.layoutName }}
                            </span>
                            <span class="delete-icon" @click.stop="handleButtonClick(openDeleteLayoutPopup, layout)">
                                ✕
                            </span>
                        </sui-dropdown-item>

                    </sui-dropdown-menu>
                </sui-dropdown>

            </sui-button-group>

        </div>

        <!-- settings -->
        <ChartFeaturesGroup />

        <comparing-symbols v-model="compareDialogOpen" />
        <SaveLayoutPopup v-model="layoutPopup" />
        <DeleteLayoutPopup v-model="popupDeleteLayout" :deleteLayoutName="deleteLayoutName"
            :deleteLayoutId="deleteLayoutId" @closePopup="closeDeleteLayoutPopup" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { TimeRangesConfig } from '../../utils/TimeRangesConfig'
import { chartTypeConfig } from '../../utils/chartTypeConfig.js'
import { chartTimeFrameConfig } from '../../utils/charttimeFrameConfig.js'
import { layoutConfig } from '../../utils/layoutConfig.js'
import { chartEvents } from '../../chartEvents';
import SaveLayoutPopup from '../dialogBoxes/saveLayout/SaveLayoutPopup.vue'
import DeleteLayoutPopup from '../dialogBoxes/saveLayout/DeleteLayoutPopup.vue'
import CustomImageIconButton from '@/baseComponents/CustomImageIconButton.vue'
import ComparingSymbols from '../dialogBoxes/ComparingSymbols.vue'
import ChartFeaturesGroup from './ChartFeaturesGroup.vue'
import { handleButtonClick } from '../../handleRestrictFeature.js'

export default {
    name: 'TopToolbox',
    components: {
        CustomImageIconButton,
        ComparingSymbols,
        SaveLayoutPopup,
        DeleteLayoutPopup,
        ChartFeaturesGroup
    },
    data() {
        return {
            timeRanges: TimeRangesConfig,
            chartTypeRanges: chartTypeConfig,
            chartTimeFrameRanges: chartTimeFrameConfig,
            layoutImageMapping: layoutConfig,
            chartImgAddress: '',
            layoutImg: '',
            popupDeleteLayout: false,
            deleteLayoutId: null,
            deleteLayoutName: '',
            compareDialogOpen: false,
        }
    },
    methods: {
        handleButtonClick,
        ...mapActions([
            'setSymbolData',
            'selectLayout'
        ]),
        toggleVloume() {
            this.$store.dispatch('toggleVolumeChart')
        },
        changeChartToCompareStocksType() {
            this.compareDialogOpen = true
        },
        changeType(newType, mob_chartImg) {

            if (this.getChartType !== newType) {
                console.log("changed from pnf", this.getChartType);
                if (this.getChartType == 'PnF') {
                    
                    setTimeout(() => {
                        this.chartImgAddress = mob_chartImg
                        this.$store.dispatch('setNewChartType', { type: newType, location: "FromTopBar" })
                        localStorage.removeItem("showLegendProps")
                    }, 500);
                }
                else {
                    this.chartImgAddress = mob_chartImg
                    this.$store.dispatch('setNewChartType', { type: newType, location: "FromTopBar" })
                }
            }

        },
        changeTimeFrame(tf) {
            const scanResultData = this.getSymbolName === this.SelectedScanSymbol
            let config = TimeRangesConfig[tf]
            let rangeMap = config.map((d) => d.range)
            this.setSymbolData({
                symbolName: this.getSymbolName,
                timeFrame: tf,
                scanResultData: scanResultData,
                chartIndex: this.selectedTab
            }).then((d) => {
                const rangeExists = Object.keys(this.userData).includes(`${tf}_range`)
                let _index = 0;
                if (rangeExists) {
                    const _range = this.userData[`${tf}_range`]
                    _index = rangeMap.findIndex((x) => { return x == _range })
                }
                if (_index < 0) _index = 0;
                setTimeout(() => {
                    chartEvents.$emit('setChartRange', rangeMap[_index], 'TimeFrameChange')
                })
                chartEvents.$emit('loadDrawingData', false, this.selectedTab)
            })
        },
        changeLayout(newType) {
            const layoutSelected = this.layoutImageMapping.find(x => x.name == newType)

            this.layoutImg = layoutSelected?.imagePath
            this.$store.commit('SET_FULLSCREEN', null)
            this.$store.commit('SET_LAYOUT', newType)
            this.$store.commit('SET_TAB_COUNT', layoutSelected?.tabCount)
        },
        async saveLayoutFun() {
            this.$store.commit('LAYOUT_POPUP', true)
        },
        async handleItemClick(layout) {
            const res = await this.selectLayout(layout);
            if (res) {
                this.changeLayout(layout.layoutType)
                this.changeType(layout.chartType || 'Candles')
            }
        },
        openDeleteLayoutPopup(layout) {
            this.deleteLayoutId = layout.id
            this.deleteLayoutName = layout.layoutName
            this.popupDeleteLayout = true
        },
        closeDeleteLayoutPopup() {
            this.deleteLayoutId = null
            this.deleteLayoutName = ""
            this.popupDeleteLayout = false
        },
        chartRangeStyle() {
            return {
                ...this.themeSecondaryColor,
                ...this.themeFontColor,
                color: 'white',
            }
        }
    },
    computed: {
        ...mapState({
            selectedChartType: (state) => state.type,
            chartToggleVolume: (state) => state.chartToggleVolume,
        }),
        ...mapGetters([
            'themeDarkMode',
            'themePrimaryColor',
            'themeSecondaryColor',
            'themeFontColor',
            'isChartLoading',
            'multiChartSymbol',
            'selectedTab',
            'SelectedScanSymbol',
            'userData',
            'getChartVisibleRange',
            'isTrainingMode',
            'layoutList',
            'layoutPopup',
            'getLayout', 
            'getChartType'
        ]),
        getSymbolName() {
            return this.multiChartSymbol[this.selectedTab]?.symbol
        },
        chartTimeframe() {
            return this.multiChartSymbol[this.selectedTab]?.timeFrame
        },
        filteredTimeRanges() {
            return this.timeRanges[this.chartTimeframe]
        },
        chartVisibleRange: {
            get() {
                return this.getChartVisibleRange
            },
            set(value) {
                if (value !== '' && value !== this.getChartVisibleRange) {
                    // let name = 'RS'
                    //let overlay_tv = Object.values(Overlays)
                    //const rsTrend = this.momentumCharts?.data?.find(x => x.name == name)
                    //const isRsAvail = rsTrend?.selected
                    // if (isRsAvail) {
                    //     this.reloadSymbol()
                    // }
                    this.$store.commit('CHART_PREDEFINE_RANGE_UPDATES')
                    const key = `${this.chartTimeframe}_range`
                    chartEvents.$emit('brush_range_change')
                    chartEvents.$emit('setChartRange', value, 'VisibleRangeChange')
                    this.$store.dispatch('setNewTimeFrameRange', { key, value })
                }
            },
        },
    },
    watch: {}

}
</script>

<style>
.item {
    padding: 0px;
    margin: 0px;
}


.button {
    padding: 15px 25px;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #04aa6d;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.button:hover {
    background-color: #3e8e41;
}

.button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

i.icon {
    background: transparent !important;
}

@media screen and (max-width: 944px) {
    .settings-group-header {
        display: none;
    }

    #timeFrameBlock1 {
        display: none !important;
    }

    #timeFrameBlock2 {
        display: none !important;
    }

    #timeFrameBlock3 {
        display: none !important;
    }

    #Chart-Controls-Block1 {
        display: none !important;
    }

    .responsiveTab {
        display: flex;
    }
}

@media screen and (min-width: 1300px) {
    #timeFrameBlock1 {
        display: none;
    }

    #timeFrameBlock2 {
        display: none;
    }

    #timeFrameBlock3 {
        display: none;
    }
}

@media screen and (max-width: 1150px) {
    #Chart-Controls-Block1 {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
    }

    #chart-controls-header {
        display: none;
    }
}

@media screen and (min-width: 1150px) {
    #Chart-Controls-Block1 {
        display: none !important;
    }
}

@media screen and (max-width: 1300px) {
    #timeFrameBlock1 {
        display: block;
        margin-left: 9px
    }

    #timeFrameBlock2 {
        display: block;
        margin-left: 5px
    }

    #timeFrameBlock3 {
        display: block;
        margin-left: 5px;
    }

    #time-frames-header {
        display: none;
    }

    #data-range-header {
        display: none;
    }

}

@media screen and (min-width: 944px) {
    .responsiveTab {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    #time-frames-header {
        display: none;
    }

    #data-range-header {
        display: none;
    }
}

.left {
    display: flex;
    height: 2.5rem;
    float: left;
}

.logo {
    height: 100%;
}

.chart-controls {
    background-color: #555;
    display: flex;
    margin-left: 20px;
    border-width: 2px;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    z-index: 101;
}

.time-frames-d1-to-M {
    background-color: #555;
    display: flex;
    margin-left: 5px;
    border-width: 2px;
    border-radius: 5px;
    color: white;
    z-index: 101;
}

.data-range {
    background-color: #555;
    display: flex;
    margin-left: 5px;
    border-width: 2px;
    border-radius: 5px;
    color: white;
    z-index: 101;
}

.settings-group {
    background-color: #555;
    display: flex;
    margin-left: 5px;
    border-width: 2px;
    border-radius: 5px;
    color: white;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    z-index: 101;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 37px;
    border-radius: 2p;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: red;
    color: white m !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .drop-btn-up {
    background-color: red;
}

.selected {
    background-color: red;
}

.button .secondary {
    font-size: 20px;
}

/* css new drop down */

.dropbtn {
    background-color: #1b1c1d;
    color: white;
    padding: 6px 19px;
    margin: 0 0.25em 0 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtnC {
    background-color: transparent;
    color: black;
    width: 100%;
    text-align: left;
    padding: 5px 16px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
}

.dropdownC {
    position: relative;
}

.dropdown-contentC {
    display: none;
    position: absolute;
    right: 0;
    background-color: rgb(255, 255, 255);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 170px;
    left: 20px;
}

.dropdown-contentC a {
    color: black;
    padding: 4px 16px;
    text-decoration: none;
    display: block;
    font-size: 0.9em;
}

.dropdown-contentC h1 {
    color: black;
    padding: 4px 16px;
    text-decoration: none;
    display: block;
    font-size: 0.9em;
}

.dropdownC:hover .dropdown-contentC {
    display: block;
}

.menu_icon {
    background-color: transparent !important;
    padding-right: 22px;
}

.header-menu-mob-btn {
    padding: 7px 10px !important;
}

@media screen and (max-width: 450px) {
    .responsiveTab .ui.button {
        padding: 0.98571429em 1em;
        width: 200% !important;
        margin: 0 !important;
    }

    .width-change {
        padding: 0.9em 1.1em !important;
    }

    .responsiveTab .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
        margin: 0 0 0 -0.21428571em;
    }

    .responsiveTab i.icon {
        margin: 0;
    }

    .header-menu-mob-btn {
        padding: 7px 10px !important;
    }
}

@media screen and (max-width: 300px) {
    .responsiveTab .ui.button {
        width: 30px !important;
    }
}

.svgImage {
    fill: currentColor;
    stroke: currentColor;
    margin-right: 10px;
}

.header-menu-nested-drpdwn {
    padding: 6px 16px;
    font-size: 0.95em;
}

.width-change-item .menu>.item {
    padding: 0.5rem 1.14285714rem !important;
}

.responsiveTab .ui.dropdown .menu>.item {
    padding: 0.6rem 1.14285714rem !important;
}

.mob-tab-chart-img {
    height: 12px !important;
    width: auto !important;
}

@media screen and (max-width: 300px) {
    .width-change {
        width: 200px;
    }

    .mob-tab-chart-img {
        height: 20px;
        width: 20px;
    }
}

.layout-item {
    text-align: center !important;
    padding-right: 1.5em
}

.delete-icon {
    text-align: right !important;
    left: 5px;
    top: 5px;
    right: 5px;
    font-size: 7px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.delete-icon:hover {
    font-size: 12px;
    border: 1px solid #333;
    border-radius: 50%;
    padding: 2px;
    background-color: #f0f0f0;
}

.dropdwn-chart {
    display: flex;
    justify-content: center;
    align-items: center
}

.dropdwn-chart-menu {
    z-index: 101;
    width: 170px;
    left: 1;
    top: 37px;
    padding-bottom: 8px;
}

.dropdwn-layout-menu {
    z-index: 101;
    width: 80px;
    left: 0;
    top: 37px;
    padding-bottom: 8px;
}

.dropdwn-text {
    margin-left: 10px;
}

.chart-control-dropdwn {
    padding-bottom: 8px;
    z-index: 101;
    width: 170px;
    left: 0;
    top: 37px;
    background-color: blueviolet !important;
}

.chart-timeframe-dropdwn {
    z-index: 101;
    width: 150px;
    left: 0;
    top: 37px;
    color: black
}

.chart-range-dropdwn {
    z-index: 101;
    width: 170px;
    left: 0;
    top: 37px;
}
</style>

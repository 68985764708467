export const chartTypeConfig = [
    {
        chartType: 'Bars',
        imagePath: '/images/chartType/bar.svg',
        image2Path: '/images/chartType/bar2.svg',
        toolTip: 'Bar Chart'
    },
    {
        chartType: 'LineChart',
        imagePath: '/images/chartType/line.svg',
        image2Path: '/images/chartType/line2.svg',
        toolTip: 'Line Chart'
    },
    {
        chartType: 'Candles',
        imagePath: '/images/chartType/candlestick.svg',
        image2Path: '/images/chartType/candlestick2.svg',
        toolTip: 'Candlestick Chart'
    },
    {
        chartType: 'Kagi',
        imagePath: '/images/chartType/kagi.svg',
        image2Path: '/images/chartType/kagi2.svg',
        toolTip: 'Kagi Chart'
    },
    {
        chartType: 'HeikenAshi',
        imagePath: '/images/chartType/heikenAshi.svg',
        image2Path: '/images/chartType/heikenAshi2.svg',
        toolTip: 'Heikin Ashi Chart'
    },
    {
        chartType: 'UpAndDown',
        imagePath: '/images/chartType/updown.svg',
        image2Path: '/images/chartType/updown2.svg',
        toolTip: 'Up/Down Chart'
    },
    {
        chartType: 'PnF',
        imagePath: '/images/chartType/point-figure.svg',
        image2Path: '/images/chartType/point-figure2.svg',
        toolTip: 'Point & Figures Chart'
    },
    {
        chartType: 'BarTrend',
        imagePath: '/images/chartType/trend.svg',
        image2Path: '/images/chartType/trend2.svg',
        toolTip: 'Bar Trend Chart'
    },

]
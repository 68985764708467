<template>
    <dialog-box size="mini" modalHeader="" :open="open" :action="false">
        <div class="ui center aligned">
            <br />
            <h4 class="ui">
                You Free Plan is about to expire soon, Please subscribe now...

            </h4>
            <br />
            <div class="ui red" v-if="showError" style="color: red">
                {{ msg }}
            </div>
        </div>
        <template v-slot:action>
            <sui-modal-actions>
                <div>
                    <sui-button negative @click.native="onSave">Subscribe</sui-button>
                    <sui-button @click.native="onClose">Close</sui-button>

                </div>
            </sui-modal-actions>
        </template>
    </dialog-box>
</template>

<script>
import { mapMutations } from 'vuex';
import DialogBox from '../../baseComponents/DialogBox'
import config from '../../config.js'
import { subscribeToPackage } from '../../services/user.js';
export default {
    name: 'SubscriptionExpirePopUp',
    components: {
        DialogBox
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            monthly_priceId: config.Monthly_priceId,
            msg: '',
            showError: false,
        }
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    methods: {
        ...mapMutations(['SET_SUB_EXP_POPUP']),
        async onSave() {
            const selectedPlan = this.$store.state.user.selectedSubsciption.toLowerCase()
            if (selectedPlan && selectedPlan) {
                const url = await subscribeToPackage(selectedPlan)
                if (url && url.error) {
                    this.showError = true
                    this.msg = url.message
                    setTimeout(() => {
                        this.showError = false
                        this.SET_SUB_EXP_POPUP(false)
                    }, 5000)
                } else {
                    window.location = url.data
                }
            } else {
                this.showError = true
                this.msg = 'Something went wrong! please try again.'
                setTimeout(() => {
                    this.showError = false
                    this.SET_SUB_EXP_POPUP(false)

                }, 3000)
            }
        },
        onClose() {
            this.SET_SUB_EXP_POPUP(false)
        }
    },
}

</script>
<style></style>
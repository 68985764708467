<template>
     <div style="height:100%;">
                    <!-- logo -->
            <!-- <img src="../assets/logo.svg" /> -->
            <img src="/images/logo-login.png" alt="Protrader Register" style="margin-bottom: 1rem; display: block;" />

        </div>
  </template>
  
  <script>
  export default {
    name: 'Header',
    props: {
   
    },
    data() {
      return {
      
      };
    },
    methods: {
  
    }
  };
  </script>
  
  <style scoped>
  
  </style>
<template>
  <div
    style="height: full; width: fit-content; background-color: transparent"
    v-if="checkFav"
  >
      <sui-button
        @click="ResetSettings()"
        secondary
        icon="undo alternate"
        data-tooltip="Reset"
        data-position="bottom center" data-inverted=""
      ></sui-button>
      <sui-button
        :disabled="!valid || userSavedScans.length >= 20"
        secondary
        icon="save outline"
        data-tooltip="Save Scan"
        data-position="bottom center" data-inverted=""
        @click="handleButtonClick(ShowPopup)"
      ></sui-button>
      
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { handleButtonClick } from '../../../handleRestrictFeature';
export default {
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    hideFav: {
      type: Boolean,
      default: false,
    },
    scanType: {
      type: String,
      default: '',
    },
  },
  name: 'ScanBottomForm',
  // components:{},
  data() {
    return {
      selectedScan: 'LatestScan',
      localScanList: [],
      propVariable: this.ScanTypeIs,
    }
  },
  computed: {
    ...mapGetters(['userSavedScans', 'userChoice','userData']),
    checkFav() {
      if (this.hideFav) {
        return false
      }
      return this.scanType.length
    },
  },
  methods: {
    handleButtonClick,
    handleSelectedOption() {
    },
    ShowPopup() {
      this.$store.commit('SCAN_POPUP', true)
    },
    Delete() {
      this.$store.commit(
        'FAV_SELECTED_SCAN_ID',
        this.localScanList[this.selectedScan].id
      )
      this.$emit('deletedScan', this.localScanList[this.selectedScan].scanName)
      this.$store.commit('SCAN_DELETE_POPUP', true)
    },
    ResetSettings() {
      this.selectedScan = ''
      this.$store.commit('SET_USER_CHOICE', false)
      this.$emit('ResetScanDefaultSettings')
    },
    getLatestScan(val) {
      let item = this.userData?.ScanRequests ?? null
      // let item = localStorage.getItem('ScanRequests')
      if (item) {
        let latestData = JSON.parse(item)
        if (this.scanType === 'samsPattern') {
        }
        if (val !== '' && val === 'LatestScan' && latestData[this.scanType]) {
          this.$store.commit('SET_USER_CHOICE', false)
          let latestDatum = latestData[this.scanType]
          // if(this.scanType === "indicators")
          this.$emit('changeScanSettings', latestDatum)
        }
      }
    },
  },
  watch: {
    userSavedScans(val) {
      this.localScanList = val.filter((v) => v.scanType === this.scanType)
    },
    userChoice() {
      if (this.userChoice) {
        this.selectedScan = ''
      }
    },
    userData(n){
      if(n){
        this.getLatestScan(this.selectedScan)
      }
    },
    selectedScan(val) {
      this.getLatestScan(val)
      if (
        val !== '' &&
        this.localScanList[val] &&
        this.localScanList[val].data
      ) {
        this.$emit('changeScanSettings', this.localScanList[val].data)
      }
    },
  },
  mounted() {
    // this.getLatestScan(this.selectedScan)
  },
}
</script>

<style scoped>
select {
  width: 120px;
  height: 32px;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0.2px 1.2px 1.5px #dddddd;
}
</style>

export const chartTimeFrameConfig = [
    {
        timeFrame:'D',
        fullName: 'Day',
        adverb:'Daily'
    },
    {
        timeFrame:'W',
        fullName: 'Week',
        adverb:'Weekly'
    },
    {
        timeFrame:'M',
        fullName: 'Month',
        adverb:'Monthly'
    }
]
<template>
  <div class="top-container top-container2">

    <TopToolLeft />
    <TopToolRight />
    <!----------------------- Create Watchlist Dialog ----------------------->
    <WatchlistScanPopup v-model="showWatchesModal" :showLoader="showLoader" :duplicationError="duplicationError"
      @createWatches="createWatches" @toggleWatchesModalInner="toggleWatchesModalInner" />

    <!----------------------- Rename Watchlist Dialog ----------------------->
    <RenameWatchlist v-model="showWatchRenameModal" :showLoader="showLoader" :newWatchName="newWatchName"
      @renameWatch="renameWatch" @toggleWatchesRenameModalInner="toggleWatchesRenameModalInner" />

    <!----------------------- Delete Watchlist Dialog ----------------------->
    <DeleteWatchlist v-model="showWatchesDeleteModal" :showLoader="showLoader" :deleteWatchName="deleteWatchName"
      @deleteWatches="deleteWatches" @toggleWatchesDeleteModalInner="toggleWatchesDeleteModalInner" />

    <!----------------------- Import Watchlist Dialog ----------------------->
    <import-watch ref="importWatchRef" v-model="showWatchesImportModalLocal" :showLoader="showLoader"
      :duplicationError="duplicationError" :createWatches="createWatchesFromImport"
      :toggleWatchesModalInner="toggleImportWatchesModalInner" />

    <!----------------------- Chart Prop Dialog ----------------------->
    <MainChartProps v-model="mainChartProps" />

    <FeatureDisablePopUp v-model="isRestrictModal" />
    
    <IntervalDisablePopUp v-model="isIntervalModal"/>

    <SubscriptionExpirePopUp v-model="isSubExpireModal"/>

    <SignUpModal v-model="isAuthModal"/>
  </div>
</template>


<script>
import TopToolLeft from './TopToolLeft.vue'
import TopToolRight from './TopToolRight.vue'
import { mapGetters, mapActions } from 'vuex'
import MainChartProps from '../dialogBoxes/MainChartProps.vue';
import WatchlistScanPopup from '../dialogBoxes/watches/WatchlistPopup.vue'
import RenameWatchlist from '../dialogBoxes/watches/RenameWatchlist.vue';
import DeleteWatchlist from '../dialogBoxes/watches/DeleteWatchlist.vue';
import ImportWatch from '../dialogBoxes/watches/ImportWatch.vue';
import FeatureDisablePopUp from '../dialogBoxes/FeatureDisablePopUp.vue';
import IntervalDisablePopUp from '../dialogBoxes/IntervalDisablePopUp.vue';
import SubscriptionExpirePopUp from '../dialogBoxes/SubscriptionExpirePopUp';
import SignUpModal from '../dialogBoxes/Authentication/SignUpModal.vue';

export default {
  name: 'TopToolBox',
  components: {
    TopToolLeft,
    MainChartProps,
    TopToolRight,
    WatchlistScanPopup,
    RenameWatchlist,
    DeleteWatchlist,
    ImportWatch,
    FeatureDisablePopUp,
    IntervalDisablePopUp,
    SubscriptionExpirePopUp,
    SignUpModal
  },
  data() {
    return {
      showLoader: false,
      duplicationError: false,
      newWatchName: '',
    }
  },
  computed: {
    ...mapGetters([
      'mainChartProps',
      'showWatchesModal',
      'watchLists',
      'scanCopyBtn',
      'showWatchRenameModal',
      'showWatchesDeleteModal',
      'selectedWatchData',
      'showWatchesImportModal',
      'isRestrictModal',
      'isIntervalModal',
      'isSubExpireModal',
      'scanResults',
      'isAuthModal'
    ]),
    deleteWatchName() {
      if (
        this.watchLists &&
        this.selectedWatchData &&
        this.selectedWatchData.name
      )
        return this.selectedWatchData.name
      else return ''
    },
    showWatchesImportModalLocal: {
      get() {
        return this.showWatchesImportModal
      },
      set(value) {
        this.toggleWatchesImportModal()
      },
    },
  },
  methods: {
    ...mapActions([
      'createNewWatchList',
      'setSelectedWatch',
      'toggleWatchesModal',
      'renameWatchListItem',
      'toggleWatchesRenameModal',
      'deleteWatchList',
      'toggleWatchesDeleteModal',
      'toggleWatchesImportModal'
    ]),
    async createWatches(name, updateModal = true) {
      // createNewWatchList
      this.showLoader = true
      let resp;
      this.newWatchName = ''
      let watchObj = this.watchLists.find((s) => s.name === name)
      if (watchObj) {
        this.duplicationError = true
        this.showLoader = false
      } else {
        if (this.scanCopyBtn) {
          resp = await this.createNewWatchList({ name: name, ScanResults: this.scanResults, updateModal },)
          this.$store.commit('SCAN_COPY_BUTTON', false)
        } else {
          resp = await this.createNewWatchList({ name: name, updateModal })
          const index = resp.data.findIndex((x) => { return x.name == name })
          this.setSelectedWatch(index)
        }
        // this.showLoader = false
        setTimeout(() => {
          this.showLoader = false
        }, 1000)

      }
      return resp
    },
    toggleWatchesModalInner() {
      this.newWatchName = ''
      this.duplicationError = false
      this.toggleWatchesModal()
    },
    renameWatch(watchName) {
      console.log(this.newWatchName + "-" + watchName);

      if (!(watchName && watchName.trim())) return;
      this.showLoader = true
      let name = watchName
      watchName = ''
      let watchObj = this.watchLists.find((s) => s.name === name)
      if (watchObj) {
        this.duplicationError = true
      } else {
        this.renameWatchListItem(name)
      }
      setTimeout(() => {
        this.showLoader = false
      }, 1000)
    },
    toggleWatchesRenameModalInner() {
      this.newWatchName = ''
      this.duplicationError = false
      this.toggleWatchesRenameModal()
    },
    deleteWatches() {
      // createNewWatchList
      this.showLoader = true
      this.deleteWatchList(this.selectedWatchData.id)
      setTimeout(() => {
        this.showLoader = false
      }, 1000)
    },
    toggleWatchesDeleteModalInner() {
      this.toggleWatchesDeleteModal()
    },
    createWatchesFromImport(name) {
      this.createWatches(name, false).then(d => {
        if (this.$refs?.importWatchRef?.updateWatchCode) {
          this.$refs?.importWatchRef?.updateWatchCode(d?.watchlist?.id)
        }
        this.toggleImportWatchesModalInner()
      })
    },
    toggleImportWatchesModalInner() {
      this.newWatchName = ''
      this.duplicationError = false
      this.$store.commit('WATCHLIST_POPUP', false)
    },
  },
}
</script>
<style>
.top-container i.icon {
  margin: 0 !important;
  background: transparent !important;
}

.top-container2 {
  border-bottom: 1px solid v-bind(colorTopBottom);
}
</style>

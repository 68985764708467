<template>
    <sui-modal v-model="open" :closable="false" :closeIcon="false" :size="'small'" class="intervalModal"
        @changed="onModalCancel">
        <sui-modal-content class="interval-content">
            <div class="">
                <img src="/images/ApplicationPopupImg.png" style="width: 100%; height: auto; border-radius: 0.5rem;" />
                <div style="margin: 1.5rem 2rem;">

                    <span class="ui" style="font-size: 28px;font-weight: 600;">
                        Ready to join the Protrader community?
                    </span><br />
                    <span style="font-size: 22px; font-weight: 400;">
                        Sign-up for your free 14 day trial now.
                    </span>
                </div>
                <div style="text-align: center;">
                    <sui-button style="font-size: 18px; border-radius: 0.5rem;" negative @click.native="onSave">Join For
                        Free</sui-button>
                </div>
            </div>
        </sui-modal-content>
    </sui-modal>

</template>

<script>
import DialogBox from '../../baseComponents/DialogBox'
import config from '../../config.js'
import { mapMutations } from 'vuex';
export default {
    name: 'IntervalDisablePopUp',
    components: {
        DialogBox
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            monthly_priceId: config.Monthly_priceId
        }
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    methods: {
        ...mapMutations(['SET_INTERVAL_POPUP', 'SET_AUTH_MODAL']),
        onSave() {
            this.SET_INTERVAL_POPUP(false)
            this.SET_AUTH_MODAL(true)
        }
    },
}

</script>
<style>
.intervalModal {
    font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    color: #131722;
}
.interval-content {
    border-radius: 3% !important;
    padding: 0.9rem !important;
}

.intervalModal>div.modal {
    border-radius: 3%;
}
</style>
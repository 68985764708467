<template>
    <div class="right-group-window" id="right_group-window">
        <sui-button info style="margin:0px">
            <a :href="training_room_link" target="_blank">
                <img :src="'/images/pt_tradingroom.png'" alt="ProTrader Trading Room"
                    style="max-height: 100%; width:4vw; " />
            </a>

        </sui-button>

        <symbol-search-box :isDisabled="isTrainingMode" @refreshInput="refreshInput" @nextSymbol="nextSymbol"
            @prevSymbol="prevSymbol" @selectSymbolModal="selectSymbolModal" @TextMatchSymbolBox="TextMatchSymbolBox"
            :show-close-button="false" v-model="selectedSymbol" />
        <div v-if="symbolSearchTextLength === 0 && symbolSearchText" style="
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          top: 40px;
          height: 40px;
          width: 100px;
          background-color: white;
          box-shadow: 0px 0px 15px 1px grey;
        ">
            No results
        </div>
        <search-symbol v-model="symbolSearch" v-on:selectSymbolModalData="selectSymbolModalData" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../config';
import { chartEvents } from '../../chartEvents';
import SymbolSearchBox from '../SymbolSearchBox.vue';
import SearchSymbol from '../../components/dialogBoxes/SearchSymbol.vue'

export default {
    name: 'TopToolRight',
    components: {
        SymbolSearchBox,
        SearchSymbol
    },
    data() {
        return {
            training_room_link: config.TRANING_ROOM_LINK,
            selectedSymbol: '',
            symbolSearch: false,
            symbolSearchText: '',
            symbolSearchTextLength: null,
        }
    },
    methods: {
        ...mapActions([
            'setSymbolData',
            'incrementSymbolIndex',
            'decrementSymbolIndex'
        ]),
        selectSymbolModalData(val) {
            this.setSymbolData({ symbolName: val, timeFrame: localStorage.getItem("SET_TIME_FRAME") || "D", chartIndex: this.selectedTab }).then((d) => {
                chartEvents.$emit('setChartRange', undefined, 'Symbol Modal')
                chartEvents.$emit('symbolChangeVerifyScanDetails')
                chartEvents.$emit('loadDrawingData', false, this.selectedTab)
            })
        },
        refreshInput() {
            this.selectedSymbol = this.selectedSymbol + ''
        },
        prevSymbol() {
            this.decrementSymbolIndex()
            this.refreshInput()
        },
        nextSymbol() {
            this.incrementSymbolIndex()
            this.refreshInput()
        },
        selectSymbolModal() {
            this.symbolSearch = !this.symbolSearch
        },
        TextMatchSymbolBox(length, text) {
            this.symbolSearchTextLength = length
            this.symbolSearchText = TextEncoder
        },
    },
    computed: {
        ...mapGetters([
            'isTrainingMode',
            'multiChartSymbol',
            'selectedTab'
        ]),
        getSymbolName() {
            return this.multiChartSymbol[this.selectedTab]?.symbol
        },
        getTimeFrame() {
            return this.multiChartSymbol[this.selectedTab]?.timeFrame
        },
    },
    watch: {
        multiChartSymbol(newVal, oldVal) {
            this.selectedSymbol = newVal[this.selectedTab]?.symbol
        },
        selectedTab(newVal, oldVal) {
            this.selectedSymbol = this.multiChartSymbol[newVal]?.symbol
        },
        selectedSymbol(newValue) {
            if (newValue && this.selectedSymbol !== this.getSymbolName) {
                this.setSymbolData({ symbolName: newValue, timeFrame: this.getTimeFrame || "D", chartIndex: this.selectedTab }).then((d) => {
                    chartEvents.$emit('setChartRange', undefined, 'SearchSelectSymbol')
                    chartEvents.$emit('symbolChangeVerifyScanDetails')
                    chartEvents.$emit('loadDrawingData', false, this.selectedTab)
                    this.$store.commit('SET_SEARCH_BOX_UPDATE', false)
                })
            }
        }
    }

}
</script>

<style>
.right-group-window {
    display: flex;
    margin-right: -5px;
    border-width: 2px;
    border-radius: 5px;
    box-sizing: border-box;
    float: right;
    width: 20%;
    justify-content: right;
}
</style>

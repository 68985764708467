export const layoutConfig = [
    {
        name: '1tab',
        imagePath: '/images/layout/layout_4tab.svg',
        darkImagePath: '/images/layout/layout_1tab_dark.svg',
        tabCount: 1
    },
    {
        name: '2tab_1',
        imagePath: '/images/layout/layout_2tab_1.svg',
        darkImagePath: '/images/layout/layout_2tab_1_dark.svg',
        tabCount: 2
    },
    {
        name: '2tab_2',
        imagePath: '/images/layout/layout_2tab_2.svg',
        darkImagePath: '/images/layout/layout_2tab_2_dark.svg',
        tabCount: 2
    },
    {
        name: '3tab_1',
        imagePath: '/images/layout/layout_3tab_1.svg',
        darkImagePath: '/images/layout/layout_3tab_1_dark.svg',
        tabCount: 3
    },
    {
        name: '3tab_2',
        imagePath: '/images/layout/layout_3tab_2.svg',
        darkImagePath: '/images/layout/layout_3tab_2_dark.svg',
        tabCount: 3
    },
    {
        name: '3tab_3',
        imagePath: '/images/layout/layout_3tab_3.svg',
        darkImagePath: '/images/layout/layout_3tab_3_dark.svg',
        tabCount: 3
    }, {
        name: '4tab',
        imagePath: '/images/layout/layout_4tab.svg',
        darkImagePath: '/images/layout/layout_4tab_dark.svg',
        tabCount: 4
    }

]
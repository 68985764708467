<template>
    <sui-modal v-model="open" :closable="false" :closeIcon="true" :size="'tiny'" class="loginModal" @changed="onCancel">
        <sui-modal-content class="loginModal-content">
            <div class="login-container"
                :style="themeDarkMode ? { background: '#151515 !important', borderColor: '#505050', color: '#A2A2A2' } : {}"
                style="margin: 2rem;">
                <div style="height:10%; margin-top: 2%; display: flex; justify-content: center;">
                    <Header></Header>
                </div>
                <div style="display: flex; justify-content: center;">
                    <span style="font-size: 2rem;text-align: center;">Sign in</span>
                </div>
                <div style="" class="">
                    <sui-form :error="error.length > 0" :loading="loading" @submit.prevent="onLogin">
                        <sui-form-field required>
                            <label :style="themeDarkMode ? { color: '#A2A2A2' } : themeFontColor">E-mail</label>
                            <input
                                :style="themeDarkMode ? { background: '#505050 !important', borderColor: '#505050', color: '#A2A2A2' } : {}"
                                class="n-input" type="email" placeholder="joe@schmoe.com" required v-model="email" />
                        </sui-form-field>
                        <sui-form-field required>
                            <label :style="themeDarkMode ? { color: '#A2A2A2' } : themeFontColor">Password</label>
                            <input
                                :style="themeDarkMode ? { background: '#505050 !important', borderColor: '#505050', color: '#A2A2A2' } : {}"
                                class="n-input" type="password" placeholder="Password" required v-model="password"
                                autocomplete="current-password" />
                        </sui-form-field>
                        <sui-message v-if="error.length > 0" error>
                            <sui-message-header>Action Forbidden</sui-message-header>
                            <p>
                                {{ error }}
                            </p>
                        </sui-message>
                    </sui-form>
                    <div class="navigate-notice">Don't have an account yet, <span
                            style="color:#0000EE ;cursor: pointer;" @click="onclickRegister(monthly_priceId)">Register
                            Monthly</span> or <span style="color:#0000EE ;cursor: pointer;"
                            @click="onclickRegister(yearly_priceId)">Register Yearly</span> </div>
                    <router-link to="forget-password"><button class="navigate-notice"
                            style="color : red; cursor: pointer; background: none ">Forgotten your password
                            ?</button></router-link>
                </div>
                <div style="text-align: center;">
                    <sui-button style="font-size: 18px; margin: 1rem; border-radius: 0.5rem;" negative
                        @click.native="onLogin">Login</sui-button>
                </div>
            </div>
        </sui-modal-content>

    </sui-modal>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import DialogBox from '../../../baseComponents/DialogBox.vue'
import config from '../../../config';
import { loginUser } from '../../../services/user';
import Header from '../../Header.vue';

export default {
    name: 'LoginModal',
    components: {
        DialogBox,
        Header
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            count: 0,
            loading: false,
            email: '',
            password: '',
            loginError: '',
            monthly_priceId: config.Monthly_priceId,
            yearly_priceId: config.Yearly_priceId,
        }
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    methods: {
        ...mapMutations(['SET_AUTH_MODAL']),
        onCancel() {
            this.$emit('onCloseModal')
        },
        gotoHome() {
            this.$router.push({ name: 'home' })
        },
        navigateToRegister() {
            this.$router.push({ name: 'register' })
        },
        onclickRegister(type) {
            if (type == this.yearly_priceId) {
                this.$store.commit('SET_PRICE_ID', this.yearly_priceId)

            }
            else {
                this.$store.commit('SET_PRICE_ID', this.monthly_priceId)
            }
            this.SET_AUTH_MODAL(true)
            this.$emit('onCloseModal')
        },

        async onLogin() {
            this.loading = true

            try {
                const res = await loginUser(this.email, this.password)
                const token = res?.data?.accessToken
                const status = res?.data?.status
                localStorage.setItem('state', token)
                localStorage.setItem('stripeSession', status)
                // if (status === 'active') {
                if (!token) throw Error('Invalid credentials')

                setTimeout(() => {
                    location.reload()
                })
                // const data = await 

                // getUserData(token).then(d => {
                //  
                // })


                // await this.$nextTick()

                // } else {
                //   setTimeout(() => {
                //     this.$router.push("/products")
                //   },0)
                // }
            } catch (e) {
                this.loginError = 'Entered credentials are incorrect'
            }

            this.loading = false
        },
    },
    computed: {
        ...mapGetters([
            'getUserId',
            'themeDarkMode',
            'themeSecondaryColor',
            'themeFontColor',
            'subscriptionStatus',
            'selectedPriceId'
        ]),
        error() {
            return this.loginError
        }
    }
}

</script>
<style>
.n-input {
    padding: 0.8rem !important;
}

.navigate-notice {
    margin-top: .5rem;
}

.loginModal .close {
    top: 1.2rem !important;
    right: 1.2rem !important;
    color: #000 !important;
    font-size: larger !important;
}

.login-container {
    font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    color: #131722;
}

.loginModal-content {
    border-radius: 3% !important;
    padding: 0.9rem !important;
}

.loginModal>div.modal {
    border-radius: 3%;
}
</style>
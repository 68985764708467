<template>
    <div>
        <div class="left top-controls-btn" id="left-setting ">
            <div class="responsiveTab" style="gap: 3px">
                <div class="dropdown" style="padding-bottom: 10px">
                    <button class="dropbtn header-menu-mob-btn "
                        style="height: 2.5rem; background-color: #414343 !important">
                        <sui-icon class="feature_menu" name="bars icon small" />
                    </button>
                    <div class="dropdown-content" :style="themePrimaryColor" style="left: 0; width: 170px">
                        <div class="dropdownC" :style="themeFontColor"
                            @mouseover="!isTrainingMode && enterThemehoverColor($event.target)"
                            @mouseout="!isTrainingMode && exitElementColor($event.target)">
                            <button class="dropbtnC header-menu-nested-drpdwn" style="padding-top: 8px"
                                :style="themeFontColor" :disabled="isTrainingMode"
                                @mouseover="!isTrainingMode && enterThemehoverColor($event.target)"
                                @mouseout="!isTrainingMode && exitElementColor($event.target)">
                                <sui-icon class="menu_icon" :style="themeFontColor" name="search icon " />
                                Scan
                            </button>
                            <div v-if="!isTrainingMode" class="dropdown-contentC" :style="themePrimaryColor"
                                style="padding-bottom: 10px; left: 35px">
                                <a @click="patterns = !patterns" :style="themeFontColor"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">Scan for
                                    Patterns & Events</a>
                                <a :style="themeFontColor" @click="activity = !activity"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">Scan For
                                    Activity</a>
                                <a :style="themeFontColor" @click="candleDialog = !candleDialog"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">Scan For
                                    candles</a>
                                <a :style="themeFontColor" @click="indicators = !indicators"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">Scan For
                                    Indicators & Events</a>
                                <a :style="themeFontColor" @click="darvasBoxes = !darvasBoxes"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">Scan For
                                    Darvas Boxes</a>
                                <a :style="themeFontColor" @click="fundementals = !fundementals"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">Scan For
                                    Fundamentals</a>

                            </div>
                        </div>
                        <div class="dropdownC" :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <button :disabled="isTrainingMode" class="dropbtnC header-menu-nested-drpdwn"
                                :style="themeFontColor"
                                @mouseover="!isTrainingMode && enterThemehoverColor($event.target)"
                                @mouseout="!isTrainingMode && exitElementColor($event.target)">
                                <sui-icon class="menu_icon" :style="themeFontColor" name="magic icon " />

                                Guru's Toolbox
                            </button>
                            <div v-if="!isTrainingMode" class="dropdown-contentC" :style="themePrimaryColor"
                                style="left: 35px; padding-bottom: 10px">
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="capitulationDialog = !capitulationDialog"
                                    @mouseout="exitElementColor($event.target)">Capitulation Days</a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="franksPatternDialog = !franksPatternDialog"
                                    @mouseout="exitElementColor($event.target)">Frank's Pattern</a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="samsPatternDialog = !samsPatternDialog"
                                    @mouseout="exitElementColor($event.target)">Sam's
                                    Darvas Boxes</a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)" @click="
                                    higherHighHigherLowsDialog = !higherHighHigherLowsDialog
                                    " @mouseout="exitElementColor($event.target)">Higher Hgh, Higher Low</a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="bollingerSqueezeDialog = !bollingerSqueezeDialog"
                                    @mouseout="exitElementColor($event.target)">Bollinger Squeeze</a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="divergenceScanDialog = !divergenceScanDialog"
                                    @mouseout="exitElementColor($event.target)">Divergence Scan
                                </a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="bigCandleDialog = !bigCandleDialog"
                                    @mouseout="exitElementColor($event.target)">Big Candle
                                </a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="pocketPivotDialog = !pocketPivotDialog"
                                    @mouseout="exitElementColor($event.target)">Pocket
                                    Piot
                                </a>
                                <a :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="heikenAshiDialog = !heikenAshiDialog"
                                    @mouseout="exitElementColor($event.target)">Heiken
                                    Ashi</a>

                            </div>
                        </div>
                        <a :style="themeFontColor" @click="favouriteScan = !favouriteScan"
                            @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <sui-icon class="menu_icon" :style="themeFontColor" name="heart icon " />

                            Favourite Scanner
                        </a>
                        <a :style="themeFontColor" @click="tradePlanner = !tradePlanner"
                            @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <sui-icon class="menu_icon" :style="themeFontColor" name="table icon " />

                            Trade planner
                        </a>
                        <a :style="themeFontColor" target="_blank" :href="friday_broadcast"
                            @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <sui-icon class="menu_icon" :style="themeFontColor" name="play circle icon " />
                            Friday broadcast</a>
                        <a :style="themeFontColor" @click="onClickTrainingMode"
                            @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <sui-icon class="menu_icon" :style="themeFontColor" name="graduation cap icon " />
                            training Mode</a>
                        <a :style="themeFontColor" @click="showSettings"
                            @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <sui-icon class="menu_icon" :style="themeFontColor" name="settings icon " />
                            Setting</a>

                        <a :style="themeFontColor" @click="checkDarkMode()"
                            @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <sui-icon class="menu_icon" :style="themeFontColor"
                                :name="themeSettings.id === 'dark' ? 'sun ' : 'moon outline'" />
                            {{ themeSettings.id === 'dark' ? 'Classic' : 'Dark' }}</a>

                        <div class="dropdownC" :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                            @mouseout="exitElementColor($event.target)">
                            <button class="dropbtnC header-menu-nested-drpdwn" style="padding-bottom: 10px"
                                :style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">
                                <sui-icon class="menu_icon" :style="themeFontColor" name="user icon " />
                                User
                            </button>
                            <div class="dropdown-contentC" :style="themePrimaryColor" v-if="getUserId">
                                <a :style="themeFontColor" @click="open = !open"
                                    @mouseover="enterThemehoverColor($event.target)"
                                    @mouseout="exitElementColor($event.target)">My Account</a>
                                <a h:style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="logoutAndNavigate" @mouseout="exitElementColor($event.target)">Logout</a>
                            </div>
                            <div class="dropdown-contentC" :style="themePrimaryColor" v-else>
                                <a h:style="themeFontColor" @mouseover="enterThemehoverColor($event.target)"
                                    @click="logInRoute" @mouseout="exitElementColor($event.target)">Login</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="settings-group r settings-group-header" id="settings-group-header"
                style="z-index: 2; font-size: 1.1em">

                <sui-button-group icons>

                    <sui-dropdown icon="search" class="button secondary" :disabled="isTrainingMode"
                        :data-tooltip="mousedown['scan'] && 'Scanning Tools'" data-position="bottom center"
                        data-inverted="" @mouseenter="mousedown['scan'] = true" @click="mousedown['scan'] = false">
                        <sui-dropdown-menu :style="themePrimaryColor">
                            <sui-dropdown-item @click="patterns = !patterns" style="font-size: 1.1em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">
                                Patterns & Events
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="activity = !activity" style="font-size: 1.1em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">
                                Activity
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="candleDialog = !candleDialog" style="font-size: 1.1em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Candles
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="indicators = !indicators" style="font-size: 1.1em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Indicator & Events
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="darvasBoxes = !darvasBoxes" style="font-size: 1.1em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Darvas Boxes
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="fundementals = !fundementals" style="font-size: 1.1em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Fundamentals
                            </sui-dropdown-item>

                        </sui-dropdown-menu>
                    </sui-dropdown>

                    <sui-dropdown icon="magic" class="button secondary" :disabled="isTrainingMode"
                        :data-tooltip="mousedown['gurus'] && 'Guru\'s Toolbox'" data-position="bottom center"
                        data-inverted="" @mouseenter="mousedown['gurus'] = true" @click="mousedown['gurus'] = false">
                        <sui-dropdown-menu :style="themePrimaryColor">
                            <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)"
                                @click="capitulationDialog = !capitulationDialog" style="font-size: 1.05em">Capitulation
                                Days
                            </sui-dropdown-item>
                            <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)"
                                @click="franksPatternDialog = !franksPatternDialog" style="font-size: 1.05em">Frank's
                                Pattern
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="samsPatternDialog = !samsPatternDialog" style="font-size: 1.05em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Sam's Darvas Boxes
                            </sui-dropdown-item>
                            <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)" @click="
                                    higherHighHigherLowsDialog = !higherHighHigherLowsDialog
                                    ">Higher High, Higher Low
                            </sui-dropdown-item>
                            <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)"
                                @click="bollingerSqueezeDialog = !bollingerSqueezeDialog">Bollinger Squeeze
                            </sui-dropdown-item>
                            <sui-dropdown-item :style="[themeFontColor, themePrimaryColor]" style="font-size: 1.05em"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)"
                                @click="divergenceScanDialog = !divergenceScanDialog">Divergence Scan
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="bigCandleDialog = !bigCandleDialog" style="font-size: 1.05em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Big Candle
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="pocketPivotDialog = !pocketPivotDialog" style="font-size: 1.05em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Pocket Pivot
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="heikenAshiDialog = !heikenAshiDialog" style="font-size: 1.05em"
                                :style="[themeFontColor, themePrimaryColor]"
                                @mouseover="enterThemehoverColor($event.target)"
                                @mouseout="exitElementColor($event.target)">Heiken Ashi
                            </sui-dropdown-item>

                        </sui-dropdown-menu>
                    </sui-dropdown>

                    <sui-button icon="heart" secondary @click="handleButtonClick(favouriteScanOnOpen)"
                        data-tooltip="Favourite Scan" data-position="bottom center" data-inverted="" />

                    <sui-button icon="table" secondary @click="handleButtonClick(tradeModalOpen)"
                        data-tooltip="Trade Planner" data-position="bottom center" data-inverted="" />

                    <sui-button icon="download" secondary data-tooltip="CSV Export" data-position="bottom center"
                        data-inverted="" />

                    <sui-button icon="play circle" secondary @click="handleButtonClick(openLink)"
                        data-tooltip="Friday Broadcast" data-position="bottom center" data-inverted="" />

                    <sui-button icon="graduation" :secondary="!isTrainingMode" :negative="isTrainingMode"
                        @click="handleButtonClick(onClickTrainingMode)" data-tooltip="Training Mode"
                        data-position="bottom center" data-inverted="" />

                    <sui-button icon="settings" secondary @click="handleButtonClick(showSettings)"
                        data-tooltip="Settings" data-position="bottom center" data-inverted="" />

                    <sui-button :icon="themeSettings.id === 'dark' ? 'sun' : 'moon outline'" secondary
                        @click="handleButtonClick(checkDarkMode)" :data-tooltip="themeSettings.id === 'dark' ? 'Classic mode' : 'Dark mode'
                            " data-position="bottom center" data-inverted="" />

                    <sui-button v-if="getUserId" secondary icon="user" :data-tooltip="'Profile'"
                        data-position="bottom center" data-inverted="" @click="open = !open" />
                    <sui-button v-if="getUserId" secondary :data-tooltip="'Logout'" data-position="bottom center"
                        data-inverted="" @click="logoutAndNavigate">Logout</sui-button>
                    <sui-button v-else secondary :data-tooltip="'Login'" data-position="bottom center" data-inverted=""
                        @click="logInRoute">Login</sui-button>

                </sui-button-group>
            </div>

        </div>
        <training-mode v-model="trainingModeDialogOpen" />
        <settings-new v-model="settingsDialog" />
        <profile-settings v-model="open" />
        <trade-planner v-model="tradePlanner" />
        <favourite-scan v-model="favouriteScan" />

        <!-- Indicator scan modals -->
        <activity v-model="activity" />
        <darvas-boxes v-model="darvasBoxes" />
        <fundementals v-model="fundementals" />
        <candle v-model="candleDialog" />
        <chart-patterns v-model="patterns" />
        <indicators v-model="indicators" />

        <!-- Guru scan modals -->
        <capitulation v-model="capitulationDialog" />
        <franks-pattern v-model="franksPatternDialog" />
        <sams-pattern v-model="samsPatternDialog" />
        <higher-high-higher-low v-model="higherHighHigherLowsDialog" />
        <bollinger-squeeze v-model="bollingerSqueezeDialog" />
        <divergence-scan v-model="divergenceScanDialog" />
        <big-candle v-model="bigCandleDialog" />
        <pocket-pivot v-model="pocketPivotDialog" />
        <heiken-ashi v-model="heikenAshiDialog" />


        <!-- Authentication modal -->
        <login-modal v-model="loginModalOpen" @onCloseModal="loginModalClose" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../config';
import TrainingMode from '../dialogBoxes/TrainingMode.vue';
import SettingsNew from '../dialogBoxes/SettingsNew.vue';
import ProfileSettings from '../dialogBoxes/ProfileSettings.vue';
import TradePlanner from '../dialogBoxes/TradePlanner.vue'
import FavouriteScan from '../dialogBoxes/FavouriteScan.vue';

///Scan dialog import 
import ChartPatterns from '../dialogBoxes/patterns/ChartPatterns.vue'
import Activity from '../dialogBoxes/patterns/Activity.vue'
import Candle from '../dialogBoxes/patterns/Candle.vue'
import Indicators from '../dialogBoxes/patterns/Indicators.vue'
import DarvasBoxes from '../dialogBoxes/patterns/DarvasBoxes.vue'
import Fundementals from '../dialogBoxes/patterns/Fundementals.vue'

///Guru scan dialog import
import Capitulation from '../dialogBoxes/guru/Capitulation.vue'
import FranksPattern from '../dialogBoxes/guru/FranksPattern.vue'
import HigherHighHigherLow from '../dialogBoxes/guru/HigherHighHigherLow.vue'
import BollingerSqueeze from '../dialogBoxes/guru/BollingerSqueeze.vue'
import DivergenceScan from '../dialogBoxes/guru/DivergenceScan.vue'
import BigCandle from '../dialogBoxes/guru/BigCandle.vue'
import PocketPivot from '../dialogBoxes/guru/PocketPivot.vue'
import HeikenAshi from '../dialogBoxes/guru/HeikenAshi.vue'
import SamsPattern from '../dialogBoxes/guru/SamsPattern.vue'
import { handleButtonClick } from '../../handleRestrictFeature';
import LoginModal from '../dialogBoxes/Authentication/LoginModal.vue';


export default {
    name: 'ChartFeaturesGroup',
    components: {
        TrainingMode,
        SettingsNew,
        ProfileSettings,
        TradePlanner,
        FavouriteScan,

        /// Scan Dialog components
        ChartPatterns,
        Activity,
        Candle,
        Indicators,
        DarvasBoxes,
        Fundementals,

        ///Guru Dialog component
        Capitulation,
        FranksPattern,
        HigherHighHigherLow,
        BollingerSqueeze,
        DivergenceScan,
        BigCandle,
        PocketPivot,
        HeikenAshi,
        SamsPattern,


        ///Authentication Modal
        LoginModal,
    },
    data() {
        return {
            friday_broadcast: config.FRIDAY_BROADCAST_LINK,
            trainingModeDialogOpen: false,
            settingsDialogKey: 0,
            settingsDialog: false,
            mousedown: { scan: true, gurus: true, profile: true },
            open: false,
            tradePlanner: false,
            favouriteScan: false,

            ///Scanner dialogs
            patterns: false,
            activity: false,
            candleDialog: false,
            indicators: false,
            darvasBoxes: false,
            fundementals: false,

            //Guru scan dialogs
            capitulationDialog: false,
            franksPatternDialog: false,
            samsPatternDialog: false,
            higherHighHigherLowsDialog: false,
            bollingerSqueezeDialog: false,
            divergenceScanDialog: false,
            bigCandleDialog: false,
            pocketPivotDialog: false,
            heikenAshiDialog: false,


            ///Authentication
            loginModalOpen: false
        }
    },
    computed: {
        ...mapGetters([
            'themePrimaryColor',
            'themeFontColor',
            'isTrainingMode',
            'themeSettings',
            'getUserId'
        ])
    },
    methods: {
        handleButtonClick,
        ...mapActions([
            'updateTrainingModeState',
            'toggleDarkMode',
            'enterThemehoverColor',
            'exitElementColor'
        ]),
        onClickTrainingMode() {
            if (this.isTrainingMode) {
                return this.updateTrainingModeState({
                    state: !this.isTrainingMode,
                })
            }

            this.trainingModeDialogOpen = !this.trainingModeDialogOpen
        },
        trainingModeDialogOnClose() {
            this.trainingModeDialogOpen = false
        },
        showSettings() {
            this.settingsDialogKey = this.settingsDialogKey + 1
            this.settingsDialog = !this.settingsDialog
        },
        checkDarkMode() {
            this.toggleDarkMode(this.themeSettings.id).then((d) => {
            })
        },
        logoutAndNavigate() {
            localStorage.removeItem('state')
            localStorage.removeItem('stripeSession')
            localStorage.removeItem('GuruRequests')
            localStorage.removeItem('ScanRequests')
            localStorage.removeItem('themeDarkModeSave')
            this.$store.commit('RESET_STATE', true)
            location.reload()
        },
        logInRoute() {
            this.loginModalOpen = true
        },
        loginModalClose() {
            this.loginModalOpen = false
        },
        openLink() {
            window.open(this.friday_broadcast, '_blank');
        },
        tradePlannerOnClose() {
            this.tradePlanner = false
        },
        favouriteScanOnClose() {
            this.favouriteScan = false
        },
        favouriteScanOnOpen() {
            this.favouriteScan = true
        },
        tradeModalOpen() {
            this.tradePlanner = true
        },
    }
}
</script>

<style>
.settings-group {
    background-color: #555;
    display: flex;
    margin-left: 5px;
    border-width: 2px;
    border-radius: 5px;
    color: white;
}

.feature_menu {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 944px) {
    #settings-group-header {
        display: none;
    }

    #responsiveTab {
        display: flex;
    }
}
</style>
<template>
    <dialog-box size="mini" modalHeader="Rename Watch" :action="false" :open="open">
        <sui-form :loading="showLoader" size="small">
            <div class="ui center aligned header">
                <br />
                <h4 class="ui header" style="font-size: 14px">
                    Please enter a name for your new watchlist:
                </h4>
                <br />

                <input type="text" @keypress.enter="onRenameWatch" v-model="watchName" />
                <template v-if="duplicationError">
                    <br />
                    <h4 class="ui red header" style="font-size: 14px">
                        A watchlist with that name already exists
                    </h4>
                    <br />
                </template>
            </div>
        </sui-form>

        <template v-slot:action>
            <sui-modal-actions>
                <div>
                    <sui-button :disabled="!(watchName && watchName.trim())" negative
                        @click.native="onRenameWatch">Create
                    </sui-button>
                    <sui-button @click.native="toggleWatchesModal">Cancel
                    </sui-button>
                </div>
            </sui-modal-actions>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from '../../../baseComponents/DialogBox'
export default {
    name: 'RenameWatchlist',
    components: {
        DialogBox
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        showWatchesModal: {
            type: Boolean,
            default: false,
        },
        showLoader: {
            type: Boolean,
            default: false,
        },
        duplicationError: {
            type: Boolean,
            default: false,
        },
        newWatchName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            watchName: this.newWatchName,
        }
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    methods: {
        onRenameWatch() {
            this.$emit('renameWatch', this.watchName)
        },
        toggleWatchesModal(){
            this.$emit('toggleWatchesRenameModalInner')
        }
    },
    watch: {
        // Sync the prop value to the local data property
        newWatchName(newVal) {
            this.watchName = newVal;
        }
    },
}

</script>
<style></style>
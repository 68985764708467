<template>
  <dialog-box :scanName="scanName" :modalHeader="header" :open="open" :action="false"
    :deleteFavScanItem="deleteFavScanItem" :showDeleteDialog="showDeleteDialog" @saveScanSettings="saveScanSettings"
    @SureDeleteFavScanItem="SureDeleteFavScanItem" @HideDeletePopup="HideDeletePopup">
    <div>
      <sui-segment :loading="isLoading" :inverted="themeDarkMode" v-bind:style="customStyle"
        :style="themeDarkMode ? { 'background-color': '#151515', border: '1px solid #2C2929' } : {}">
        <sui-form>
          <slot />
          <!-- <PatternBaseModal /> -->
          <div>
            <sui-header dividing :style="themeFontColor" class="fontSize">Filters</sui-header>
            <div class="input-container fontSize">
              <base-check-box label="" v-model="settings.crossesAndAboveBelow.isActive" @click="handleUserChoice" />
              <select type="select" v-model="settings.crossesAndAboveBelow.left" :style="selectWidth"
                @change="handleUserChoice">
                <option value="close">Close</option>
                <option value="ma">MA</option>
                <option value="wma">WMA</option>
                <option value="xma">XMA</option>
              </select>

              <sui-input @input="handleUserChoice" :style="selectWidth"
                v-if="settings.crossesAndAboveBelow.left !== 'close'" type="number" tabindex="1" class="hidden"
                v-model="settings.crossesAndAboveBelow.leftPeriod" @keypress="preventDecimal" />

              <select type="select" v-model="settings.crossesAndAboveBelow.operatorType" :style="selectWidth"
                @change="handleUserChoice">
                <option value="above">is Above</option>
                <option value="below">is Below</option>
                <option value="crossAbove">Crossed Above</option>
                <option value="crossBelow">Crossed Below</option>
              </select>

              <select type="select" v-model="settings.crossesAndAboveBelow.right" :style="selectWidth"
                @change="handleUserChoice">
                <option value="close">Close</option>
                <option value="ma">MA</option>
                <option value="wma">WMA</option>
                <option value="xma">XMA</option>
              </select>

              <sui-input :style="[
                selectWidth,
                themeDarkMode ? { 'background-color': '#505050' } : {},
              ]" v-if="settings.crossesAndAboveBelow.right !== 'close'" type="number" tabindex="1" class="hidden"
                v-model="settings.crossesAndAboveBelow.rightPeriod" @input="handleUserChoice"
                @keypress="preventDecimal" />
            </div>
            <!-- 1st filter -->
            <div class="input-container fontSize">
              <base-check-box @click="handleUserChoice" label="Price range in dollars from"
                v-model="settings.priceRangeDollar.isActive" />
              <sui-input type="number" tabindex="1" class="hidden" :style="[
                textBoxStyle,
                themeDarkMode ? { 'background-color': '#505050' } : {},
              ]" v-model="settings.priceRangeDollar.start" @input="handleUserChoice" />
              <label>to</label>
              <sui-input type="number" tabindex="1" class="hidden" :style="[
                textBoxStyle,
                themeDarkMode ? { 'background-color': '#505050' } : {},
              ]" v-model="settings.priceRangeDollar.end" @input="handleUserChoice" />
            </div>

            <!-- 2nd filter -->
            <div class="input-container fontSize">
              <base-check-box label="Ignore results that have less than" v-model="settings.ignoreResults.isActive"
                @click="handleUserChoice" />
              <sui-input type="number" tabindex="1" class="hidden" v-model="settings.ignoreResults.minDays" :style="[
                textBoxStyle,
                themeDarkMode ? { 'background-color': '#505050' } : {},
              ]" @input="handleUserChoice" @keypress="preventDecimal" />
              <span>day(s) of data</span>
            </div>

            <!-- 3rd filter -->
            <div class="input-container fontSize">
              <base-check-box label="Ignore results that have no trading range for the last day"
                v-model="settings.isIgnoreResultsWithNoRangeForLastDay" @click="handleUserChoice" />
            </div>

            <!-- 4th filter -->
            <div class="input-container fontSize">
              <base-check-box label="Only show results that have traded in the past"
                v-model="settings.onlyShowResultsThatHaveTraded.isActive" @click="handleUserChoice" />
              <sui-input type="number" tabindex="1" class="hidden"
                v-model="settings.onlyShowResultsThatHaveTraded.pastDays" :style="[
                  textBoxStyle,
                  themeDarkMode ? { 'background-color': '#505050' } : {},
                ]" @input="handleUserChoice" @keypress="preventDecimal" />
              <label>day(s)</label>
            </div>

            <!-- 5th filter -->
            <div class="input-container fontSize">
              <base-check-box label="Ignore results that have value less than"
                v-model="settings.ignoreResultsWithValueLess.isActive" @click="handleUserChoice" />
              <sui-input type="number" tabindex="1" class="hidden" v-model="settings.ignoreResultsWithValueLess.value"
                :style="[
                  textBoxStyle,
                  themeDarkMode ? { 'background-color': '#505050' } : {},
                ]" @input="handleUserChoice" />
            </div>

            <!-- 6th filter -->
            <div class="input-container fontSize">
              <base-check-box label="Ignore results that have volume less than"
                v-model="settings.ignoreResultsWithVolumeLess.isActive" @click="handleUserChoice" />
              <sui-input type="number" tabindex="1" class="hidden" v-model="settings.ignoreResultsWithVolumeLess.volume"
                :style="[
                  textBoxStyle,
                  themeDarkMode ? { 'background-color': '#505050' } : {},
                ]" @input="handleUserChoice" />
            </div>

            <!-- 6th filter -->
            <div class="input-container fontSize">
              <base-check-box label="Exclude intra-day data" v-model="settings.isExcludeIntraDayData"
                @click="handleUserChoice" />
            </div>
          </div>
          <DataSettings :id="id" type="guru" />
        </sui-form>
      </sui-segment>

    </div>
    <template v-slot:action>
      <sui-modal-actions class="gurus-action-bar" :style="{ ...themePrimaryColor, ...themeFontColor }">
        <div>
          <sui-button icon="undo" secondary @click="onResetGurus" />
          <sui-button secondary icon="save outline" @click="handleButtonClick(ShowPopup)"
            :disabled="userSavedScans.length >= 20"></sui-button>
        </div>
        <div>

        </div>
        <div>
          <sui-button :disabled="!valid" :style="themeSecondaryColor" @click.native="stopScan" v-if="isLoading">
            Stop
          </sui-button>
          <sui-button style="color:white;" :disabled="!valid" :style="themeSecondaryColor" @click.native="handleButtonClick(startScan)"
            v-if="!isLoading">
            Scan
          </sui-button>
          <sui-button @click.native="onClose" secondary>Cancel</sui-button>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogBox from '@/baseComponents/DialogBox.vue'
import DataSettings from '../DataSettings.vue'
import PatternBaseModal from '../patterns/PatternBaseModal.vue'
import BaseCheckBox from '@/baseComponents/BaseCheckBox.vue'
import { handleButtonClick } from '../../../handleRestrictFeature'

export default {
  name: 'GuruBaseModal',
  components: {
    DialogBox,
    DataSettings,
    PatternBaseModal,
    BaseCheckBox
  },

  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    scanType: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: "Guru's Tool"
    },
    valid: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    },
    scanName: {
      type: String,
      default: "",
    },
    isLoading: { type: Boolean, default: false }
  },
  watch: {
    userData(n) {
      if (n) {
        this.handleUpdateLatestGuru()
      }
    }
  },
  methods: {
    handleButtonClick,
    ...mapActions(['resetGurusSettings', 'setGuruFilterSettings',
      'setGuruFilterSettingsOld', 'getUserScanList',]),
    ShowPopup() {
      this.$store.commit('SCAN_POPUP', true)
    },
    handleUpdateLatestGuru() {
      let guruScan = this.userData?.GuruRequests ?? null;
      if (guruScan) {
        let scan = JSON.parse(guruScan);
        if (scan[this.scanType]) {
          const payload = {
            id: this.id,
            settings: scan[this.scanType]
          }
          this.$store.commit("UPDATE_LATEST_GURU_SETTINGS", payload)
          const value = scan[this.scanType]?.settings?.selectedMarket;
          this.$store.dispatch('setSelectedMarketNew', { id: this.id, value: value, type: "guru" })
        }
      }
    },
    HideDeletePopup() {
      this.showDeleteDialog = false
    },
    saveScanSettings(name) {
      this.$emit('saveScanSettings', name)
    },
    SureDeleteFavScanItem(check) {
      this.deleteFavScan = check
      this.deletedScan()
    },
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    preventDecimal(event) {
      if (event.key === '.' || event.key === ',' || event.key === '-') {
        event.preventDefault();
      }
    },
    onClose() {
      this.$emit('onClose')
    },
    stopScan() {
      this.$emit('stop')
    },
    startScan() {
      this.$emit('ok')
    },
    onResetGurus() {
      this.resetGurusSettings()
    }
  },
  data() {
    return {
      inputFontColor: 'black',
      inputBorderColor: '1px solid blue',
      inputBackColor: 'white',
      deleteFavScan: false,
      showDeleteDialog: false,
      deleteFavScanItem: '',
      settingsData: {
        crossesAndAboveBelow: {
          isActive: false,
          left: 'close',
          leftPeriod: 14,
          operatorType: 'above',
          right: 'ma',
          rightPeriod: 14,
        },
        priceRangeDollar: {
          isActive: false,
          start: 0,
          end: 0,
        },
        ignoreResults: {
          isActive: false,
          minDays: 280,
        },
        isIgnoreResultsWithNoRangeForLastDay: false,
        onlyShowResultsThatHaveTraded: {
          isActive: false,
          pastDays: 10,
        },
        ignoreResultsWithValueLess: {
          isActive: false,
          value: 25000,
        },
        ignoreResultsWithVolumeLess: {
          isActive: false,
          volume: 25000,
        },
        isExcludeIntraDayData: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserSettingData',
      'filterSettingsForGuruScan',
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeDarkMode',
      'themeFontColor',
      'userData',
      'userChoice',
      'userSavedScans'
    ]),
    modalID() {
      return this.$props.id
    },
    settings: {
      get() {
        if (this.modalID) {
          let filterSettingsForGuruScan = this.filterSettingsForGuruScan(this.modalID)
          if (!filterSettingsForGuruScan) {
            let value = JSON.parse(JSON.stringify(this.settingsData))
            this.setGuruFilterSettings({
              id: this.modalID,
              settings: value,
            }).then((d) => {
              return this.filterSettingsForGuruScan(this.modalID)
            })
          }
          return filterSettingsForGuruScan || this.settingsData
        }

        return this.filterSettings || this.settingsData
      },
      set(value) {
        if (this.modalID) {
          this.setGuruFilterSettings({ id: this.modalID, settings: value })
        } else this.setGuruFilterSettingsOld(value)
      },
    },
    selectWidth() {
      return 'width: 12%'
    },
    textBoxStyle() {
      return 'width: 10%'
    }
  }
}
</script>

<style>
.gurus-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 13.5px;
}

.input-container>* {
  margin-right: 0.3rem;
}

.fontSize {
  font-size: 13.5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 870px) {
  .fontSize {
    font-size: 11px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 510px) {
  .fontSize {
    font-size: 10px;
    margin-bottom: 7px;
  }
}
</style>

<template>
  <div v-if="showrighttoolbox" :class="{ active: isActive }">
    <div class="side-bar f-wh small-font " style="overflow-y: auto !important;" :style="themePrimaryColor"
      id="toggleSidebar">
      <div :style="getMarketId !== 'FX' && metaDataStyle" style="padding-left: 0.5em">
        <div style="display: flex; justify-content: space-between">
          <div style="">
            <b :title="this.multiChartSymbol[this.selectedTab]?.companyName.length > 29 ? this.multiChartSymbol[this.selectedTab]?.companyName : ''" :style="themeFontColor">
              {{ this.multiChartSymbol[this.selectedTab]?.companyName.length <= 29 ? this.multiChartSymbol[this.selectedTab]?.companyName : this.multiChartSymbol[this.selectedTab]?.companyName.slice(0, 26) + '...' }} </b>
          </div>
          <div>
            <a :href="`https://www.google.com/search?q=${this.multiChartSymbol[this.selectedTab]?.companyName}`" target="_blank" :style="themeFontColor">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                :stroke="themeSecondaryColor.backgroundColor" style="width: 15px">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
              </svg>
            </a>
          </div>
        </div>
        <sui-list v-if="getMarketId !== 'FX'">
          <sui-list-item v-for="metadata of metaData" :key="metadata.left" class="scrollbar-hidden">
            <div style="display: flex; justify-content: space-between">
              <div :style="themeFontColor">{{ metadata.left }}</div>
              <div :style="themeFontColor" :title="metadata.right.length > 22 ? metadata.right : ''">
                {{ metadata.right.length <= 22 ? metadata.right : metadata.right.slice(0, 19) + '...' }} </div>
              </div>
          </sui-list-item>
        </sui-list>
      </div>
      <sui-divider />

      <!-- ohlcv and time data -->
      <div style="width: 100%; padding-left: 0.5em">
        <div class="ui action input" style="max-width: 100%; display: flex">
          <input type="text" placeholder="Current Candle" :readonly='true' :style="fieldColor"
            style="width: 60%; padding: 0.65rem !important" :value="selectedCandle" />

          <sui-button size="mini" icon="angle left" secondary :disabled="isTrainingMode ? traningModeIndex === 0 : candleIndex === 0
            " @click="decreaseIndex" ref="decreasecandleref" :style="trainingModeStyles" />

          <sui-button size="mini" icon="angle right" secondary :disabled="isTrainingMode
              ? traningModeIndex >= maxLength - 1
              : candleIndex >= maxLength - 1
            " @click="increaseIndex" :style="trainingModeStyles" ref="increasecandleref" />


        </div>
        <sui-list>
          <sui-list-item v-for="symData of ohlcvData" :key="symData.left">
            <div style="display: flex; justify-content: space-between">
              <div :style="themeFontColor">{{ symData.left }}</div>
              <div :style="themeFontColor">{{ symData.right }}</div>
            </div>
          </sui-list-item>
        </sui-list>
      </div>
      <sui-divider />

      <!-- scan results-->
      <div style="max-width: 100%; padding-left: 0.5em">
        <div class="row" style="max-width: 100%">
          <b :style="themeFontColor">
            Scan Results {{ resultsCount > 0 ? `: (${resultsCount})` : '(0)' }}
          </b>
        </div>

        <div class="row ui small action input" style="width: 100%; display: flex">
          <select name="scanResultsList" id="scanResultsList" :style="dropDownColor" style="width: 100%"
            v-model="selectedScan">
            <option v-for="scan of scanResults" :style="themeFontColor" :key="scan.scanId" :value="scan.scanId">
              {{ scan.count }}
            </option>
          </select>

          <!-- <sui-button size="mini" icon="angle down" secondary /> -->
          <sui-button size="mini" icon="angle copy" secondary :disabled="!scanResults.length"
            :data-tooltip="'Create Watch List'" @click="ScanCopyBtn(), showCreateWatchList()" />
          <!-- <sui-button size="mini" icon="angle trash" secondary disabled /> -->
        </div>

        <div class="row ui small action input" style="width: 100%; display: flex; margin-top: 5px">
          <div class="scan">
            <div style="display: flex">
              <div class="scan-BtnDiv" @click="OpenDropDown" :style="dropDownColor">
                <img v-if="scanSearchTopImage" style="width: 10px" :src="scanSearchTopImage" alt="" />

                <span v-else-if="scanSearchTopText" style="font-size: 8.45px; font-weight: bold"
                  :style="themeFontColor">{{ scanSearchTopText }}</span>
                <span v-else-if="scanSearchPatternName" style="font-size: 8.45px; font-weight: bold"
                  :style="themeFontColor">{{ scanSearchPatternName }}</span>
                &nbsp;
                <span :style="themeFontColor">
                  {{ scanSearchSymbolName }}
                </span>
              </div>
              <button class="scan-dropbtn" @click="OpenDropDown">
                <sui-icon style="
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  " :name="openDropDownScan ? 'caret up' : 'caret down'" />
              </button>
            </div>
            <div v-if="openDropDownScan" class="scan-content">
              <a v-for="(sym, index) of selectedScanResults" :style="dropDownColor" :key="`${sym.symbol ? sym.symbol + sym.patternName : sym
                }-${Date.now()}`" @click="AssignSymbol(sym, index, true)">
                <span v-if="sym['patternName']">
                  <img v-if="scanSearchImg[sym.patternName]?.key === 'icon'" style="width: 10px"
                    :src="scanSearchImg[sym.patternName].img" alt="" />
                  <span v-else-if="scanSearchImg[sym.patternName]?.key === 'text'"
                    style="font-size: 8.45px; font-weight: bold">{{ scanSearchImg[sym.patternName].code }}</span>
                  <span v-else-if="sym.patternName?.length" style="font-size: 8.45px; font-weight: bold">{{
                    sym.patternName }}</span>
                </span>

                <span :style="themeFontColor"> &nbsp; {{ sym.symbol }}</span>
              </a>
            </div>
          </div>
          <sui-button size="mini" icon="angle left" secondary @dblclick="startPlaying(symbolSelectBack)"
            @click="handleClick(symbolSelectBack)" :disabled="shouldAllowBackSymbol" />
          <sui-button size="mini" icon="pause" secondary @click="stopPlaying" :disabled="!isPlaying" />
          <sui-button size="mini" icon="angle right" secondary @dblclick="startPlaying(symbolSelectNext)"
            @click="handleClick(symbolSelectNext)" :disabled="shouldAllowNextSymbol" />

          <sui-button size="mini" icon="angle download" secondary @click="scanListDownload"
            :disabled="!scanResults.length" />
        </div>
      </div>
      <sui-divider />

      <!-- watchlist-->
      <div style="max-width: 100%; padding-left: 0.5em">
        <div class="row">
          <b :style="themeFontColor">Watchlists</b>
        </div>
        <div class="row ui small action input" style="max-width: 100%; display: flex">
          <input type="text" placeholder="Current Candle" value="-- NONE --" style="width: 50%" disabled
            v-if="watchLists.length === 0" />
          <select name="watchListData" id="watchListData" style="width: 100%" :style="dropDownColor"
            v-model="selectedWatchLocal" :key="watchListMainId" v-else>
            <option v-for="(w, i) of watchLists" :key="i" :value="i" :style="themeFontColor">
              {{ w.name }}
            </option>
          </select>
          <!-- <sui-button size="mini" icon="angle down" secondary disabled /> -->
          <sui-button size="mini" icon="angle bars" secondary @click="showWatchOptions" />
        </div>
        <div class="row ui small action input" style="
            max-width: 100%;
            display: flex;
            justify-content: flex-end;
            height: 0px;
          ">
          <div class="dropup-content" v-if="showWatchOpt" style="position: relative; bottom: 0px">
            <button @click="handleButtonClick(showCreateWatchList), closeCreateWatchList()" :style="themeDarkMode
                ? { backgroundColor: '#505050', color: '#A2A2A2' }
                : {}
              " @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">
              Create
            </button>
            <button :style="themeDarkMode
                ? { backgroundColor: '#505050', color: '#A2A2A2' }
                : {}
              " @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
              @click="handleButtonClick(showCreateWatchRename), closeCreateWatchList()" v-if="selectedWatch.length > 0">
              Rename
            </button>
            <button :style="themeDarkMode
                ? { backgroundColor: '#505050', color: '#A2A2A2' }
                : {}
              " @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
              @click="handleButtonClick(showDeleteWatch), closeCreateWatchList()" v-if="selectedWatch.length > 0">
              Delete
            </button>
            <button @click="handleButtonClick(importWatches), closeCreateWatchList()" :style="themeDarkMode
                ? { backgroundColor: '#505050', color: '#A2A2A2' }
                : {}
              " @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)">
              Import
            </button>
            <button :style="themeDarkMode
                ? { backgroundColor: '#505050', color: '#A2A2A2' }
                : {}
              " @mouseover="enterThemehoverColor($event.target)" @mouseout="exitElementColor($event.target)"
              @click="handleButtonClick(downloadWatches), closeCreateWatchList()" v-if="selectedWatch.length > 0">
              Export
            </button>
          </div>
        </div>

        <div class="row ui small action input" style="max-width: 100%; display: flex; margin-top: 5px; z-index: 1">
          <input type="text" placeholder="Current Candle" value="-- NONE --" style="width: 30%" disabled
            v-if="watchLists.length === 0" />
          <select name="watchListDataIndex" id="watchListDataIndex" ref="watchListDataIndex" :style="dropDownColor"
            style="width: 100%" v-model="selectedWatchCodeLocal" :key="watchListItemId" v-else>
            <option v-for="(w, i) of watchListsData" :key="i" :value="i" :style="themeFontColor"
              v-if="selectedWatch.length">
              {{ w.name }}
            </option>
          </select>
          <div style="display: inline-block">
            <div class="watchlist-button-group">
              <!-- <sui-button
                size="mini"
                icon="angle down"
                secondary
                :disabled="
                  // !selectedWatch.length
                  selectedWatchData == null
                "
                style="width: 2rem; margin: 0; border-radius: 0"
              /> -->
              <sui-button size="mini" icon="angle left" secondary :disabled="stopBackwardWatchList"
                style="width: 2rem; margin: 0; border-radius: 0" @click="handleClick(goBackward)"
                @dblclick="startPlayingWatchlist(goBackward)" />
              <sui-button size="mini" icon="pause" secondary @click="stopPlayingWatchlist"
                :disabled="!isPlayingWatchlist" style="width: 2rem; margin: 0; border-radius: 0" />
              <sui-button size="mini" icon="angle right" secondary :disabled="stopForwardWatchList"
                @click="handleClick(goForward)" @dblclick="startPlayingWatchlist(goForward)"
                style="width: 2rem; margin: 0; border-radius: 0" />
              <sui-button size="mini" icon="angle plus" secondary @click="addCurrentCode" :disabled="selectedWatchData == null || isCurrentNodeAlreadyExist
                " style="width: 2rem; margin: 0; border-radius: 0" />
              <sui-button size="mini" icon="angle minus" secondary :disabled="
                // !selectedWatch.length
                selectedWatchData == null ||
                selectedWatchCodeLocal === -1 ||
                watchListsData.length === 0
                " @click="removeCurrentCode" style="width: 2rem; margin: 0; border-radius: 0" />
            </div>
          </div>
        </div>
      </div>
      <sui-divider />

      <!-- health bar -->
      <div style="max-width: 100%; padding-left: 0.5em">
        <div class="row">
          <b :style="themeFontColor">Health Report ({{ !isNaN(getHealthPct.toFixed(2)) ? getHealthPct.toFixed(2) : 0
            }}/340)</b>
        </div>
        <div class="row">
          <health-bar :percentage="getHealthPct" :allTest="getHealthAll" />
        </div>
      </div>

      <!-- intraday toggle -->
      <div style="max-width: 100%; margin-top: 1rem; padding-left: 0.5em">
        <div class="row">
          <b :style="themeFontColor">Intraday {{ timeFrameText }} Data
            {{ isIntradayModeAllowed ? '' : ' (Markets are closed)' }}</b>
        </div>
        <div class="row" style="padding-bottom: 5px">
          <sui-button-group class="">
            <sui-button secondary icon @click="intradayMode = true" :disabled="!isIntradayModeAllowed"
              :style="intradayMode ? themeSecondaryColor : {}" style="">On</sui-button>
            <sui-button secondary icon @click="intradayMode = false" :disabled="!isIntradayModeAllowed"
              :style="!intradayMode ? themeSecondaryColor : {}">Off</sui-button>
          </sui-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HealthBar from 'BaseComponents/HealthBar'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import DialogBox from '../baseComponents/DialogBox'
import { TimeFrameMap } from '../constants'
import { getMarketTime } from '../utils'
//<<<<<<< HEAD
import SymbolSearchBox from './SymbolSearchBox.vue'
import WatchesLoader from './WatchesLoader.vue'
import Imag from '../../public/images/patterns/ascending_triangle.svg'
import { chartEvents } from '../chartEvents'
import { FormatUnixDate } from '../utils/candle'
import { handleButtonClick } from '../handleRestrictFeature'
//=======
//>>>>>>> 36c1366226a27aa1747def882b55450960ac3a02
export default {
  name: 'RightToolBox',
  components: { HealthBar, DialogBox, WatchesLoader, SymbolSearchBox, Imag },
  mounted() {
    setInterval(() => {
      this.$store.commit('UPDATE_SCAN_RESULTS', ++this.counterValue)
    }, 1000)
    this.resize()
    window.addEventListener('resize', this.resize)
    chartEvents.$on('setTimeFrameToSplitter', this.setTimeStampToSplitter)
    chartEvents.$on(
      'symbolChangeVerifyScanDetails',
      this.symbolChangeVerifyScanDetails
    )
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('keydown', this.handleKeyDown);
    this.stopPlaying();
    this.stopPlayingWatchlist();
    // chartEvents.removeEventListener('setTimeStampToSplitter', this.setTimeStampToSplitter)
  },
  data() {
    return {
      hoverColorDrpd: '',
      scanSearchImg: {
        // pattern icon
        SaucerBottom: {
          key: 'icon',
          img: '/images/patterns/saucer_bottom.svg',
        },
        DoubleBottom: {
          key: 'icon',
          img: '/images/patterns/double_bottom.svg',
        },
        BullishPennant: {
          key: 'icon',
          img: '/images/patterns/bullish_pennant.svg',
        },
        BearishFlag: {
          key: 'icon',
          img: '/images/patterns/bearish_flag.svg',
        },
        AscendingTriangle: {
          key: 'icon',
          img: '/images/patterns/ascending_triangle.svg',
        },
        DoubleTop: {
          key: 'icon',
          img: '/images/patterns/double_top.svg',
        },
        TriPointResistance: {
          key: 'icon',
          img: '/images/patterns/tri_point_resistance.svg',
        },
        BearishPennant: {
          key: 'icon',
          img: '/images/patterns/bearish_pennant.svg',
        },
        DescendingTriangle: {
          key: 'icon',
          img: '/images/patterns/descending_triangle.svg',
        },
        TriPointSupport: {
          key: 'icon',
          img: '/images/patterns/tri_point_support.svg',
        },
        BullishFlag: {
          key: 'icon',
          img: '/images/patterns/bullish_flag.svg',
        },

        // Candle stick icon

        Doji: {
          key: 'icon',
          img: '/images/candlesticks/doji.svg',
        },
        InvertedHammer: {
          key: 'icon',
          img: '/images/candlesticks/inverted-hammer.svg',
        },
        BullishEngulfing: {
          key: 'icon',
          img: '/images/candlesticks/bullish-engulfing.svg',
        },
        BullishHarami: {
          key: 'icon',
          img: '/images/candlesticks/bullish-harami.svg',
        },
        BearishEngulfing: {
          key: 'icon',
          img: '/images/candlesticks/bearish-engulfing.svg',
        },
        BearishHarami: {
          key: 'icon',
          img: '/images/candlesticks/bearish-harami.svg',
        },
        Hammer: {
          key: 'icon',
          img: '/images/candlesticks/hammer.svg',
        },
        HangingMan: {
          key: 'icon',
          img: '/images/candlesticks/hanging-man.svg',
        },
        Inside : {
          key: 'icon',
          img: '/images/candlesticks/inside.svg',
        },
        MultipleInside : {
          key: 'icon',
          img: '/images/candlesticks/multipleInside.svg',
        },
        GtMultipleInside: {
          key: 'icon',
          img: '/images/candlesticks/multipleInside.svg',
        },

        // Text / Events code

        TCIBreak: {
          key: 'text',
          code: 'TCI ',
        },
        TCITrend: {
          key: 'text',
          code: 'TCI ',
        },
        MacdSetting: {
          key: 'text',
          code: 'MACD ',
        },
        ObvAbove: {
          key: 'text',
          code: 'OBV ',
        },
        VolumeMore: {
          key: 'text',
          code: 'VOL ',
        },

        // Text / Indicator code
        Cross: {
          key: 'text',
          code: 'CROSS ',
        },
        Roc: {
          key: 'text',
          code: 'ROC ',
        },
        RSI: {
          key: 'text',
          code: 'RSI ',
        },
        Stoc: {
          key: 'text',
          code: 'STOCK ',
        },
        Bands: {
          key: 'text',
          code: 'BANDS ',
        },
        VolumeIncrease: {
          key: 'text',
          code: 'Vol-Inc ',
        },
        TrendLineCrossing: {
          key: 'text',
          code: 'TX',
        },

      },
      scanSearchSymbolName: '',
      scanSearchPatternName: '',
      scanSearchTopText: '',
      scanSearchTopImage: null,
      openDropDownScan: false,
      isActive: false,
      showrighttoolbox: true,
      selectedScan: 'none',
      selectedSymbol: {
        patternName: null,
        symbol: '',
      },
      chartWidth: 0,
      chartHeight: 0,
      selectedScanResultIndex: 0,
      watchListItemId: 0,
      watchListMainId: 0,
      counterValue: 0,
      showWatchOpt: false,
      showCreateWatchModal: false,
      isPlaying: false,
      playInterval: null,
      clickTimeout: null,
      isPlayingWatchlist: false,
      playIntervalWatchlist: null
    }
  },

  methods: {
    handleButtonClick,
    ...mapActions([
      'exitElementColor',
      'enterThemehoverColor',
      'changeSymbolToScannedSymbol',
      'addDrawingForScannedSymbol',
      'updateIntraDayModeState',
      'setSelectedWatch',
      'addNewSymbolToList',
      'setSelectedWatchCode',
      'removeCurrentSymbolFromList',
      'toggleWatchesDeleteModal',
      'toggleWatchesImportModal',
      'updateTrainingModeState',
    ]),
    increaseIndex() {
      if (this.$refs?.increasecandleref?.disabled) return
      if (this.isTrainingMode) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: this.candleIndexJumpSelected.index + 1,
          chartHit: false,
        })
      } else {
        this.candleIndex++
      }
    },
    decreaseIndex() {
      if (this.$refs?.decreasecandleref?.disabled) return
      if (this.isTrainingMode) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: this.candleIndexJumpSelected.index - 1,
          chartHit: false,
        })
      } else {
        this.candleIndex--
      }
    },
    ScanCopyBtn() {
      this.$store.commit('SCAN_COPY_BUTTON', true)
      this.$store.commit('SELECTED_SCAN_ID', this.selectedScan)
    },
    OpenDropDown() {
      this.openDropDownScan = !this.openDropDownScan
      this.hoverColorDrpd = this.themeSecondaryColor.backgroundColor
    },
    AssignSymbol(sym, index = 0, setIndex = false) {
      this.selectedSymbol = sym
      //this method is used a lot and not everyone using this method has index
      //setIndex is a check key, false by default only true when select box for symbol list
      //calls this method.
      if (setIndex) {
        this.selectedScanResultIndex = index;
        //this.selectedScanResults.findIndex((s)=>s.symbol===sym.symbol)
      }
      this.scanSearchTopImage = null
      this.scanSearchTopText = null
      this.scanSearchSymbolName = null
      if (sym) {
        this.scanSearchSymbolName = sym.symbol
        let patternName = sym.patternName
        if (patternName && patternName.length) {
          let scanSearchImgElement = this.scanSearchImg[patternName]
          let key = scanSearchImgElement?.key
          let isText = key === 'text'
          let isIcon = key === 'icon'
          if (isIcon) {
            
            this.scanSearchTopImage = scanSearchImgElement.img
          } else if (isText) {
            this.scanSearchTopText = scanSearchImgElement.code
          } else {
            this.scanSearchPatternName = patternName
          }
        } else {
          this.scanSearchPatternName = ''
        }
      }

      this.openDropDownScan = false
    },
    showWatchOptions() {
      this.showWatchOpt = !this.showWatchOpt
    },
    showCreateWatchList() {
      // this.showWatchOpt = false
      this.showCreateWatchModal = true
      this.$store.dispatch('toggleWatchesModal')
    },
    closeCreateWatchList(){
      this.showWatchOpt = false
    },
    showCreateWatchRename() {
      this.showWatchOpt = false
      this.showCreateWatchModal = true
      this.$store.dispatch('toggleWatchesRenameModal')
      // this.createNewWatchList({ScanResults:this.scanResults})
    },
    showDeleteWatch() {
      this.showWatchOpt = false
      this.toggleWatchesDeleteModal()
    },
    importWatches() {
      this.showWatchOpt = false
      this.toggleWatchesImportModal()
    },
    scanListDownload() {
      let scans = this.getScans()[0]
      let results = scans.results
      let symbolList = this.getSymbolsList
      // symbolList.filter(d=> d.startsWith(scans.results[0].symbol))
      let symbolInfoList = []
      for (let result of results) {
        let symbolInfo = symbolList.find((d) => d.startsWith(result.symbol))
        symbolInfoList.push(symbolInfo)
      }

      // debugger
      //
      let resData = []
      for (let info of symbolInfoList) {
        let symbolArray = info.split('~')
        if (symbolArray && symbolArray.length === 2) {
          let symbolName = symbolArray[0]
          let marketId = symbolArray[1]
          resData.push(`${symbolName},${marketId}\n`)
        }
      }
      let blob = new Blob(resData, { type: 'text/txt;charset=utf-8' })
      saveAs(
        blob,
        `${scans.scanType.toUpperCase()}-${scans.createdDate.valueOf()}.txt`
      )
    },
    downloadWatches() {
      this.showWatchOpt = false

      let selectedWatchData = ''
      for (let i in this.watchLists) {
        if (this.watchLists[i].id === this.selectedWatch) {
          selectedWatchData = this.watchLists[i]
        }
      }

      let resData = []
      for (let selectedWatchDatum of selectedWatchData.symbolList) {
        //   s.startsWith({selectedWatchDatum} )
        // )
        // if (symbolFull) {
        //   }
        resData.push(`${selectedWatchDatum.name},${selectedWatchDatum.id}\n`)
      }
      let blob = new Blob(resData, { type: 'text/txt;charset=utf-8' })
      saveAs(blob, `${this.selectedWatch}.txt`)
    },
    watchSelect(e) {
    },
    resize() {
      this.chartWidth = window.innerWidth
      this.chartHeight = window.innerHeight
    },
    getScans() {
      return this.scanResults.filter(
        (scan) =>
          `${scan.scanType} - ${scan.timeFrame} - ${scan.date}` ===
          this.selectedScan
      )
    },
    /** @param {number} value */
    formatMillions(value) {
      if (value >= 1000) {
        return (value / 1000.0).toFixed(3) + 'B'
      }
      return value.toFixed(3) + 'M'
    },

    async addCurrentCode() {
      let index = await this.addNewSymbolToList({
        name: this.getSymbolName,
        id: this.getMarketId,
      })
      this.selectedWatchCodeLocal = index
      this.updateWatchListItem()
    },
    async goForward() {
      this.selectedWatchCodeLocal = this.selectedWatchCodeLocal + 1
    },
    async goBackward() {
      this.selectedWatchCodeLocal = this.selectedWatchCodeLocal - 1
    },
    startPlayingWatchlist(callback) {
      if (this.isPlaying) {
        this.stopPlaying()
      }
      if (this.isPlayingWatchlist) {
        this.stopPlayingWatchlist()
      }
      this.isPlayingWatchlist = true;
      this.playIntervalWatchlist = setInterval(() => {
        callback();
      }, 2000); // 10 seconds
    },
    stopPlayingWatchlist() {
      this.isPlayingWatchlist = false;
      clearInterval(this.playIntervalWatchlist);
      this.playIntervalWatchlist = null;
    },
    async removeCurrentCode() {
      this.selectedWatchCodeLocal = await this.removeCurrentSymbolFromList({
        name: this.getSymbolName,
        id: this.getMarketId,
        index: this.selectedWatchCodeLocal,
      })
      // this.selectedWatchCodeLocal=''
      this.updateWatchListItem()
    },
    updateWatchListItem() {
      this.watchListItemId = this.watchListItemId + 1
    },
    handleClick(callback) {
      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
      } else {
        this.clickTimeout = setTimeout(() => {
          callback();
          this.clickTimeout = null;
        }, 300); // Adjust the delay as needed
      }
    },
    symbolSelectNext() {
      if (this.selectedScanResultIndex >= this.selectedScanResults.length - 1)
        return
      this.selectedScanResultIndex++
      this.selectedSymbol =
        this.selectedScanResults[this.selectedScanResultIndex]
      this.AssignSymbol(this.selectedSymbol)
    },
    symbolSelectBack() {
      if (this.selectedScanResultIndex <= 0) return
      this.selectedScanResultIndex--
      this.selectedSymbol =
        this.selectedScanResults[this.selectedScanResultIndex]
      this.AssignSymbol(this.selectedSymbol)
    },
    startPlaying(callback) {
      if (this.isPlayingWatchlist) {
        this.stopPlayingWatchlist()
      }
      if (this.isPlaying) {
        this.stopPlaying()
      }
      this.isPlaying = true;
      this.playInterval = setInterval(() => {
        callback();
      }, 2000); // 10 seconds
    },
    stopPlaying() {
      this.isPlaying = false;
      clearInterval(this.playInterval);
      this.playInterval = null;
    },
    setTimeStampToSplitter(timeStamp, indexBased = false) {
      if (indexBased) {
        // Checking For Mouse Clisk Event which always send indexBased Param as true
        const chartData = this.multiTv[this.selectedTab]?.data?.get_one(`chart.data`)
        // debugger
        if (chartData.length && chartData.length >= timeStamp) {
          let candleData = chartData[timeStamp]
          if (candleData && candleData[0]) {
            timeStamp = candleData[0]
          }
        } else {
          return
        }
      }
      const splitters = this.multiTv[this.selectedTab]?.data?.get(`onchart.Splitters`)
      if (splitters) {
        splitters.forEach((splitter) => {
          if (splitter.name === 'CurrentCandle') {
            return this.multiTv[this.selectedTab]?.data?.set(`${splitter.id}.data`, [[timeStamp]])
          }
        })
      }
    },
    showSymbolScanDetails(symbolName) {
      const { timeFrame, isDrawing, scanType, settings } = this.getScans()[0]
      this.changeSymbolToScannedSymbol({
        symbolData: symbolName,
        timeFrame,
        isDrawing,
        scanType,
        settings,
      }).then((d) => { })
    },
    symbolChangeVerifyScanDetails() {
      const scans = this.getScans()

      if (
        scans &&
        scans.length > 0 &&
        this.getSymbolName === this.selectedSymbol?.symbol
      ) {
        const { scanType, settings } = scans[0]
        const symbolData = this.selectedSymbol
        this.addDrawingForScannedSymbol({ symbolData, scanType, settings })
      }
    },
    handleKeyDown(event) {

      if (event.code === 'Space' || event.key === ' ') {
        if (this.isPlaying) {
          this.stopPlaying()
        }
        if (this.isPlayingWatchlist) {
          this.stopPlayingWatchlist()
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'themeDarkMode',
      'scanCopyBtn',
      'candleIndexSelected',
      'candleIndexJumpSelected',
      'trainingOffset',
      // 'formattedSymbolData',
      'scanResults',
      'symbolMetaData',
      'getSymbolHealth',
      'dc',
      'isTrainingMode',
      'isIntradayMode',
      'trainingModeStartingIndex',
      'trainingModeStartingIndexSubset',
      'trainingModeDataRaw',
      'trainingModeData',
      'getMarketId',
      'timeFrame',
      'themeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'watchLists',
      'showWatchRenameModal',
      'watchListsData',
      'selectedWatch',
      'selectedWatchCode',
      'getSymbolsList',
      'selectedWatchSymbolCode',
      'symbolClassificationInfo',
      'themeDarkMode',
      'selectedWatchData',
      'multiChartSymbol',
      'selectedTab',
      'multiOhlcv',
      'multiTv'
    ]),
    getSymbolName() {
      return this.multiChartSymbol[this.selectedTab]?.symbol
    },
    trainingModeSelected(){
      return this.multiChartSymbol[this.selectedTab]?.data
    },
    dropDownColor() {
      return this.themeDarkMode
        ? {
          background: '#505050 !important',
          borderColor: '#505050',
          color: '#A2A2A2',
        }
        : this.themeFontColor
    },
    candleIndex: {
      get() {
        return this.candleIndexSelected?.index
      },
      set(value) {
        this.$store.commit('SET_CANDLE_INDEX_UPD', {
          time: null,
          index: value,
          chartHit: false,
        })
      },
    },
    traningModeIndex: {
      get() {
        return (
          this.trainingModeStartingIndex +
          (this.candleIndexJumpSelected?.index - this.trainingOffset)
        )
      },
      set(value) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: value,
          chartHit: false,
        })
      },
    },
    fieldColor() {
      return this.themeDarkMode
        ? {
          background: '#505050 !important',
          borderColor: '#505050',
          color: '#A2A2A2',
        }
        : {}
    },
    metaData() {
      let classificationInfo = this.multiChartSymbol[this.selectedTab]?.classificationInfo
      let metaDataInfo = this.multiChartSymbol[this.selectedTab]?.metaData
      return [
        { left: 'Sector', right: classificationInfo?.sector || '' },
        {
          left: 'Industry',
          right: classificationInfo?.industry || '',
        },
        {
          left: 'Sub Industry',
          right: classificationInfo?.subIndustry || '',
        },
        {
          left: 'Issued',
          right: metaDataInfo?.issue
            ? this.formatMillions(metaDataInfo?.issue / 1000000.0)
            : '',
        },
        {
          left: 'Market Cap',
          right: metaDataInfo?.marketCap
            ? this.formatMillions(metaDataInfo?.marketCap)
            : '',
        },
        {
          left: 'PE Ratio',
          right: metaDataInfo?.peRatio?.toFixed(3) || '',
        },
        {
          left: 'EPS',
          right: metaDataInfo?.eps?.toFixed(3) || '',
        },
        {
          left: 'Earning Yields',
          right:
            metaDataInfo?.earningYield?.toFixed(3) == null
              ? 'N/A'
              : metaDataInfo?.earningYield?.toFixed(3) + '%',
        },

        {
          left: 'Trading Basis',
          right: metaDataInfo?.tradingBasis || '',
        },
        {
          left: 'Dividend Rate',
          right: metaDataInfo?.dividendRate?.toFixed(3) || '',
        },
        {
          left: 'Dividend Yield',
          right:
            metaDataInfo?.dividendYield?.toFixed(3) == null
              ? 'N/A'
              : metaDataInfo?.dividendYield?.toFixed(3) + '%',
        },
        {
          left: 'Franking %',
          right: metaDataInfo?.frankedPct
            ? metaDataInfo?.frankedPct?.toFixed(3) + '%'
            : '0%',
        },
        {
          left: '1m Return',
          right:
            metaDataInfo?.return1m?.toFixed(3) == null
              ? 'N/A'
              : metaDataInfo?.return1m?.toFixed(3) + '%',
        },
        {
          left: '3m Return',
          right:
            metaDataInfo?.return3m?.toFixed(3) == null
              ? 'N/A'
              : metaDataInfo?.return3m?.toFixed(3) + '%',
        },
        {
          left: '6m Return',
          right:
            metaDataInfo?.return6m?.toFixed(3) == null
              ? 'N/A'
              : metaDataInfo?.return6m?.toFixed(3) + '%',
        },
        {
          left: '12m Return',
          right:
            metaDataInfo?.return12m?.toFixed(3) == null
              ? 'N/A'
              : metaDataInfo?.return12m?.toFixed(3) + '%',
        },
      ]
    },
    selectedWatchLocal: {
      get() {
        return this.selectedWatchCode
      },
      set(value) {
        this.setSelectedWatch(value)
        this.selectedWatchCodeLocal = -1
      },
    },
    selectedWatchCodeLocal: {
      get() {
        return this.selectedWatchSymbolCode
      },
      set(value) {
        if (
          value !== -1 &&
          this.watchListsData[value] &&
          this.watchListsData[value].name
        )
          this.setSelectedWatchCode({
            id: value,
            name: this.watchListsData[value].name,
          })
      },
    },
    isCurrentNodeAlreadyExist() {
      if (this.watchListsData.length) {
        let filter = this.watchListsData.filter(
          (f) => f.name === this.getSymbolName
        )
        return filter.length > 0
      }
      return false
    },
    /** @returns {[String]} */
    selectedScanResults() {
      if (this.selectedScan === 'none' || this.selectedScan === '') return []

      return this.getScans()[0].results
    },
    /** @returns {Number} */
    maxLength() {
      
      return this.trainingModeSelected?.length
    },
    /** @returns {String} */
    selectedCandle() {
      let symbolData = this.trainingModeSelected
      return this.isTrainingMode
        ? moment(
          this.trainingModeSelected?.[this.traningModeIndex]?.date
        ).format('Do MMM YYYY')
        : moment(symbolData?.[this.candleIndex]?.date).format(
          'Do MMM YYYY'
        )
    },
    /** @returns {[{left: String, right: String}]} */
    ohlcvData() {
      let symbolDataInfo = this.trainingModeSelected
      return [
        {
          left: 'Open',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[this.traningModeIndex]?.open?.toFixed(
              3
            )
            : symbolDataInfo?.[this.candleIndex]?.open?.toFixed(3),
        },
        {
          left: 'High',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[this.traningModeIndex]?.high?.toFixed(
              3
            )
            : symbolDataInfo?.[this.candleIndex]?.high?.toFixed(3),
        },
        {
          left: 'Low',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[this.traningModeIndex]?.low?.toFixed(3)
            : symbolDataInfo?.[this.candleIndex]?.low?.toFixed(3),
        },
        {
          left: 'Close',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[this.traningModeIndex]?.close?.toFixed(
              3
            )
            : symbolDataInfo?.[this.candleIndex]?.close?.toFixed(3),
        },
        {
          left: 'Volume',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[
              this.traningModeIndex
            ]?.volume?.toLocaleString()
            : symbolDataInfo?.[
              this.candleIndex
            ]?.volume?.toLocaleString(),
        },
        {
          left: 'Value',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[
              this.traningModeIndex
            ]?.value?.toLocaleString()
            : symbolDataInfo?.[
              this.candleIndex
            ]?.value?.toLocaleString(),
        },
        {
          left: 'VWAP',
          right: this.isTrainingMode
            ? this.trainingModeSelected?.[
              this.traningModeIndex
            ]?.vwap?.toLocaleString()
            : symbolDataInfo?.[this.candleIndex]?.vwap === "NaN" ? "N/A"
              : symbolDataInfo?.[this.candleIndex]?.vwap?.toFixed(3),
        },
      ]
    },
    /** @returns {Number}} */
    resultsCount() {
      return this.selectedScanResults.length > 0
        ? this.selectedScanResults?.length
        : 0
    },
    /** @returns {Number}} */
    getHealthPct() {
      const healthVal1 = this.multiChartSymbol[this.selectedTab]?.health.test1 + this.multiChartSymbol[this.selectedTab]?.health.test2 + this.multiChartSymbol[this.selectedTab]?.health.test3 + this.multiChartSymbol[this.selectedTab]?.health.test4 + this.multiChartSymbol[this.selectedTab]?.health.test5;
      return healthVal1
    },
    getHealthAll() {
      return this.getSymbolHealth || {
        test1: 0,
        test2: 0,
        test3: 0,
        test4: 0,
        test5: 0,
      }
    },
    /** @returns {Number}} */
    metaDataStyle() {
      // let maxHeight = '35%'
      // if (this.chartHeight < 785) maxHeight = '20%'
      return `min-height: 100px; overflow-y: auto;margin-top:0px`
    },
    //  togglebox(){
    //     // return `display : none`
    // },
    /** @returns {boolean}} */
    shouldAllowNextSymbol() {
      return (
        this.resultsCount === 0 ||
        this.selectedScanResultIndex >= this.selectedScanResults.length - 1
      )
    },
    /** @returns {boolean}} */
    shouldAllowBackSymbol() {
      return this.resultsCount === 0 || this.selectedScanResultIndex <= 0
    },

    stopForwardWatchList() {
      return (
        this.selectedWatchData == null ||
        this.selectedWatchCodeLocal === this.watchListsData.length ||
        this.selectedWatchCodeLocal + 1 === this.watchListsData.length
      )
    },
    stopBackwardWatchList() {
      return (
        this.selectedWatchData == null ||
        this.selectedWatchCodeLocal === 0 ||
        this.selectedWatchCodeLocal === -1
      )
    },

    /** @returns {String} */
    trainingModeStyles() {
      return this.isTrainingMode ? 'background: #029acf' : ''
    },
    /** @returns {Boolean} */
    intradayMode: {
      get() {
        return this.isIntradayMode
      },
      set(val) {
        if (this.isIntradayMode !== val) {
          this.updateIntraDayModeState(val)
        }
      },
    },
    /** @returns {Boolean} */
    isIntradayModeAllowed() {
      const { isMarketOpened } = getMarketTime(this.getMarketId)
      return isMarketOpened
    },
    intradayTooltip() {
      return this.isIntradayModeAllowed ? '' : 'Markets are closed'
    },
    /** @returns {String} */
    timeFrameText() {
      return TimeFrameMap[this.timeFrame]
    },
  },
  updated() {
  },
  watch: {
    themeSecondaryColor() {
      this.hoverColorDrpd = this.themeSecondaryColor.backgroundColor
    },
    selectedScan(val) {
      // this.selectedScanResultIndex = 0
      this.selectedSymbol =
        this.selectedScanResults[this.selectedScanResultIndex]
      this.AssignSymbol(this.selectedSymbol)
    },
    scanResults(newVal) {
      if (newVal.length === 0) return

      const latest = newVal[newVal.length - 1]
      this.selectedScan = `${latest?.scanType} - ${latest?.timeFrame} - ${latest?.date}`
      if (latest.results.length > 0) {
        this.selectedScanResultIndex = 0
        this.selectedSymbol = latest.results[0]
        this.AssignSymbol(latest.results[0])
      }
    },
    selectedSymbol(symbolName) {
      if (!symbolName) return
      this.showSymbolScanDetails(symbolName)
    },
    symbolName(newSymbol) {
    },
    // formattedSymbolData(newValue) {
    //   //TODO:CHANGE_EFFECTS
    //   if (newValue?.length > 0 && this.maxLength) {
    //     this.candleIndex = this.maxLength - 1
    //     const splitters = this.dc.get('onchart.Splitters')
    //     if (splitters) {
    //       splitters.forEach((splitter) => {
    //         if (splitter.name === 'CurrentCandle') {
    //           return this.dc.set(`${splitter.id}.data`, [
    //             [
    //               moment(
    //                 this.trainingModeSelected?.[this.candleIndex]?.date
    //               ).valueOf(),
    //             ],
    //           ])
    //         }
    //       })
    //     }
    //   }
    // },
    maxLength(newValue, oldValue) {
      // if (!oldValue && newValue && !this.candleIndex) {
        this.candleIndex = newValue - 1
      // }
    },
    async candleIndexSelected(newValue, oldValue) {
      if (!oldValue) return

      if (newValue?.index < this.multiOhlcv[this.selectedTab].length) {
        const timeFrame = this.multiOhlcv[this.selectedTab][newValue?.index][0]
        this.setTimeStampToSplitter(timeFrame)
      }
    },
    async candleIndexJumpSelected(newValue, oldValue) {
      if (!oldValue || !this.isTrainingMode) return
      
      const symbolData = this.multiOhlcv[this.selectedTab]
      let trainingModeStartingIndex = this.trainingModeStartingIndex
      const trainingOffset = this.trainingOffset
      let selectedIndex = newValue?.index
      let selectedIndexOld = oldValue?.index
      const selectedTime = newValue?.time
      let trainingModeDataRaw = JSON.parse(
        JSON.stringify(this.trainingModeDataRaw)
      )
      if (selectedIndex <= trainingOffset) {
        if (selectedTime) {
          // When we are using chart to change data index
          this.setTimeStampToSplitter(selectedTime)
        } else {
          // when we are using arrow button to change data index
          if (selectedIndex >= 0) {
            const timeFrame = trainingModeDataRaw[selectedIndex][0]
            this.setTimeStampToSplitter(timeFrame)
          }
        }
        return
      }
      const selectedFromChart = newValue?.chartHit
      let endPoint = 0
      if (selectedFromChart) {
        let index = 0
        //   find index

        for (const dt of symbolData) {
          if (dt[0] > selectedTime) break
          index++
        }
        endPoint = index
      } else {
        endPoint = trainingModeStartingIndex + (selectedIndex - trainingOffset)
      }

      const maximumIndex = symbolData.length
      if (endPoint <= maximumIndex && this.isTrainingMode) {
        const trainingModeData = this.trainingModeData
        const oldIndexStart = selectedIndexOld + 1
        let startPoint =
          trainingModeStartingIndex + (oldIndexStart - trainingOffset)
        if (selectedIndex > selectedIndexOld) {

          if (selectedFromChart) {
            let sliceData = symbolData.slice(startPoint, endPoint)
            let slicedDataIndex = 0
            for (let i = oldIndexStart; i <= selectedIndex; i++) {
              trainingModeDataRaw[i] = sliceData[slicedDataIndex]
              slicedDataIndex++
            }
          } else {
            trainingModeDataRaw[selectedIndex] = symbolData[startPoint]
          }
          trainingModeDataRaw = trainingModeDataRaw.map((candle) => [
            candle[0],
            candle[1] ? candle[1] : NaN,
            candle[2] ? candle[2] : NaN,
            candle[3] ? candle[3] : NaN,
            candle[4] ? candle[4] : NaN,
            candle[5] ? candle[5] : NaN,
            candle[6] ? candle[6] : NaN,
            candle[7] ? candle[7] : NaN,
          ])
          // debugger
          // debugger
          // this.dc.merge('chart.data', sliceData)
          this.updateTrainingModeState({
            state: this.isTrainingMode,
            startingIndex: trainingModeStartingIndex,
            trainingDataIndex: trainingModeData.length - 1,
            trainingModeData,
            trainingModeDataRaw: trainingModeDataRaw,
          })
        } else if (selectedIndex < selectedIndexOld) {
          for (let i = trainingModeDataRaw.length - 1; i > selectedIndex; i--) {
            trainingModeDataRaw[i] = [
              trainingModeDataRaw[i][0],
              NaN,
              NaN,
              NaN,
              NaN,
              NaN,
              NaN,
              NaN,
            ]
          }
          this.updateTrainingModeState({
            state: this.isTrainingMode,
            startingIndex: trainingModeStartingIndex,
            trainingDataIndex: trainingModeData.length - 1,
            trainingModeData,
            trainingModeDataRaw: trainingModeDataRaw,
          })
          // this.dc.data.chart.data.pop()
        }
        if (selectedTime) this.setTimeStampToSplitter(selectedTime)
        else {
          const timeFrame = trainingModeDataRaw[selectedIndex][0]
          this.setTimeStampToSplitter(timeFrame)
        }
      }
    },
    isTrainingMode() {
      if (this.isTrainingMode) {
        this.traningModeIndex = this.trainingModeStartingIndexSubset
      } else {
        
        this.candleIndex = this.trainingModeSelected?.length - 1
      }
    },
    showWatchRenameModal(n, o) {
      if (!n) {
        this.watchListMainId = this.watchListMainId + 1
      }
    },
    shouldAllowNextSymbol(n, o) {
      if (n == true) {
        this.stopPlaying()
      }
    },
    shouldAllowBackSymbol(n, o) {
      if (n == true) {
        this.stopPlaying()
      }
    },
    stopBackwardWatchList(n, o) {
      if (n == true) {
        this.stopPlayingWatchlist()
      }
    },
    stopForwardWatchList(n, o) {
      if (n == true) {
        this.stopPlayingWatchlist()
      }
    }

  }
}
</script>

<style>
.scan {
  position: relative;
  display: inline-block;
  z-index: 101;
}

/* Dropdown Content (Hidden by Default) */
.scan-content {
  position: absolute;
  background-color: #ffffff;
  width: 125px;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  border-radius: 2px;
  max-height: 25vh;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.scan-content a {
  color: black;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  display: flex;
  gap: 5px;
}

.scan-content a:hover {
  color: black;
  background-color: v-bind(hoverColorDrpd);
}

.scan-dropbtn {
  background-color: #414343;
  color: white;
  height: 28px;
  width: 25px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-BtnDiv {
  width: 72px;
  height: 28px;
  background-color: #ffffff;
  border: 1px solid #777777;
  display: flex;
  align-items: center;
  padding: 0 5px;
  gap: 3px;
}

/* Show the dropdown menu on hover */
/* .owais:active .owais-content {
  display: block;
} */

@media screen and (max-width: 1000px) {
  #toggleSidebar {
    /* display: none; */
  }
}

/* .isActive{

 } */

.small-font {
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.05em;
  /*  font-size:0.95em;   changed*/
  /* font-weight: 600; */
  /* letter-spacing: 1px; */
}

/* .f-wh{
  margin-bottom: 2px;
} */

.watchlist-button-group {
  display: flex;
  max-width: 50%;
}
</style>

<template>
    <dialog-box size="mini" modalHeader="Delete Watch":open="open" :action="false">
        <!--      <h3 class="">-->
        <sui-form :loading="showLoader" size="small">
            <div class="ui center aligned">
                <br />
                <h4 class="ui">
                    Are you sure you want to remove your
                    <span style="color: red">({{ deleteWatchName }})</span> watchlist?
                </h4>
                <br />
            </div>
        </sui-form>
        <!--      </h3> -->
        <template v-slot:action>
            <sui-modal-actions>
                <div>
                    <sui-button negative @click.native="deleteWatchFun">Yes </sui-button>
                    <sui-button @click.native="toggleModal">No
                    </sui-button>
                </div>
            </sui-modal-actions>
        </template>
        <!-- <div v-if="showLoader" class="loaderStyle" >
      <watches-loader/>
    </div> -->
    </dialog-box>
</template>

<script>
import DialogBox from '../../../baseComponents/DialogBox'
export default {
    name: 'DeleteWatchlist',
    components: {
        DialogBox
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        showLoader: {
            type: Boolean,
            default: false,
        },
        deleteWatchName: {
            type: String,
            default: ''
        }
        
    },
    data() {
        return {
            
        }
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    methods: {
        deleteWatchFun(){
        this.$emit('deleteWatches')
       },
       toggleModal(){
        this.$emit('toggleWatchesDeleteModalInner')
       }
    },
    watch: {
        
    },
}

</script>
<style></style>